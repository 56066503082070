import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import SectionCard from "../Components/CommonComponents/SectionCard";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import Seo from "../Components/CommonComponents/Seo";

function TermsAndConditions() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      {/* Navbar Start  */}
      <Seo
        title={"Terms and Conditions | Nextbigbox "}
        description={"Best IT Solutions in Delhi NCR."}
        name={"Nextbigbox"}
        type={"Article"}
      />
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      {/* Navbar End  */}
      {/* Web Seo Main Component  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <div className="px-[2rem] py-[5rem] md:px-[5.3rem] md:py-[8rem] privacy_policy terms_policy">
          <h1 className="text-[2.5rem] text-center md:text-start font-proximaNovaExtraBold mb-14 leading-[2.6rem] ">
            Our Terms And Conditions
          </h1>
          <ul className="md:mt-2 md:w-[90%]">
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                Interpretation
              </li>
              <ul>
                <li>
                  The words capitalizing the initial letter have meanings
                  outlined below the following conditions. The following
                  definitions shall have constant, which means whether they seem
                  singular or plural.{" "}
                </li>
              </ul>
            </ul>
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mt-8">
                Definitions
              </li>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                For the demands of all Terms and Conditions:
              </li>
              <ul>
                <li>
                  A partner means that associate degree entity that controls is
                  management led by,or is below are the accepted control with a
                  celebration, wherever “control” means that custody of more
                  than fifty or a lot of the division, equity interest, or
                  different balance entitled to vote for the appointment of
                  authority or alternative managing control. Country refers to
                  India Company (referred to as either “the Company,” “We,”
                  “Us,” or “Our” during this Agreement) refers to Next Big Box
                  Pvt. Ltd.
                </li>
              </ul>
            </ul>
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                Device
              </li>
              <ul>
                <li>
                  It means any device that may access the Service like a pc, a
                  cellular telephone, or a digital pill.{" "}
                </li>
              </ul>
            </ul>
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                Service refers to the website.
              </li>
              <ul>
                <li>
                  Terms and Conditions are those that type whole Agreement
                  between You and the Company concerning the employment of the
                  Service. Terms and Conditions agreement has been made with the
                  assistance of the Terms and Conditions generator and the
                  Third-party Social Media Service means that any services or
                  content (including knowledge, info, product, or services)
                  provided by a third party will be displayed, enclosed, or
                  created by the Service.
                </li>
                <li>
                  You mean that an individual accessing or victimizing the
                  Service or corporate or alternative legal entity on behalf of
                  that such individual is accessing or crushing the Service, as
                  applicable.
                </li>
                <li>
                  These unit Terms and Conditions govern service utilization and
                  agreement between you. Therefore, the company Terms and
                  Conditions initiated the rights and obligations of all users
                  regarding the use of the Service.
                </li>
                <li>
                  Your access to the Service employment is conditioned on your
                  acceptance of compliance with all these Terms and Conditions.
                  These Terms and Conditions involve all.
                </li>
                <li>
                  By accessing or victimizing the Service, you accommodate these
                  Terms and Conditions. If you’re a part of these Terms and
                  Conditions, you’ll not access the Service.
                </li>
                <li>
                  You represent that you are simply a unit over the age of
                  eighteen. The company does not permit those below eighteen to
                  use the Service.
                </li>
                <li>
                  Your service access and use area unit to boot conditioned on
                  your acceptance of compliance with the Privacy Policy company.
                  Our policies and procedures of community, use, and disclosure
                  of your information when you utilize the appliance or the
                  website tell regarding Your privacy rights and, therefore, the
                  approach the law protects.
                </li>
              </ul>
            </ul>
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                Links to totally different websites
              </li>
              <ul>
                <li>
                  Our Service may contain all links to third-party websites or
                  services that are not in hand or controlled by the company.
                </li>
                <li>
                  The company has no management and assumes no responsibility
                  for the content or practices of any third-party websites or
                  services. An extra acknowledgment and agreement that the
                  company will not be responsible or liable immediately for any
                  harm or loss caused by in relevance the use of or assurance on
                  any such content, product, and services out there any such
                  websites or services.
                </li>
                <li>
                  We powerfully advise you to browse the terms and conditions
                  and privacy policies of any third-party websites or services
                  simply.
                </li>
              </ul>
            </ul>
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                Termination
              </li>
              <ul>
                <li>
                  We might terminate or suspend your access currently, whereas
                  not previous notice or liability, for any reason no matter,
                  further as whereas not control, if You break these Terms and
                  Conditions. Upon expiry, your right to use the Service will
                  stop currently.
                </li>
              </ul>
            </ul>
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                Limitation of Liability
              </li>
              <ul>
                <li>
                  Not with standing and any damages simply might incur, the
                  entire liability of the company and any of its suppliers at a
                  lower place any provision of this Terms and Conditions. Your
                  exclusive remedy for all preceding shall be restricted to the
                  quantity paid by your Service.
                </li>
                <li>
                  To the maximum extent permissible by applicable law, in no
                  event shall the company or its suppliers be answerable for any
                  special, incidental, indirect, or import damages no matter
                  including, but not restricted to, damages for loss of profits,
                  and loss or different data, for business cut-off, for personal
                  harm, loss of privacy coming out of in any manner relevant to
                  the use of or failure to use the Service, third-party code and
                  third-party hardware used with the Service, or otherwise
                  concerning any provision of this terms and conditions, albeit
                  the company or any supplier has been steered off the prospect
                  of such damages and fails of its essential purpose.
                </li>
                <li>
                  Few states do not allow the exclusion of covert warranties or
                  limitation of liability for incidental or import damages,
                  which means that a variety of the rules may not apply. In
                  these states, each party’s liability will be restricted to the
                  most effective extent by law.
                </li>
                <li>
                  The Service is provided to you with all faults and defects,
                  not warrantless. To the foremost extent permissible at a lower
                  place, applicable law, and the company, on behalf of its
                  Affiliates and their several licensors and repair suppliers,
                  expressly disclaims all warranties, whether or not or not
                  categorical, implied, statutory, or otherwise, regarding the
                  Service, further as all covert guarantee of state, fitness for
                  a selected purpose, title, and non-infringement, and
                  warranties that may arise out finally of the dealing, course
                  of performance, usage or trade apply.
                </li>
                <li>
                  While not a limitation to the preceding, the company provides
                  no warrant or endeavor. It makes no illustration of any kind
                  of Service will meet, bring home the bacon any meant results,
                  be compatible or work with the opposite code, applications,
                  systems, or services, operate whereas not an interruption,
                  meet any performance or reliability standards or be error-free
                  or that any errors or defects can or area unit attending to
                  correct.
                </li>
                <li>
                  Without tying leading, neither company nor any company’s
                  providers make any illustration or warranty of any kind,
                  categorical or implied:
                </li>
                <ul className="mt-2 mb-2 list-disc ml-10">
                  <li>
                    On the service’s operation or convenience, data, content,
                    and materials or product boxed thereon.
                  </li>
                  <li>
                    The Service area unit attending is uninterrupted or
                    error-free.
                  </li>
                  <li>
                    On the efficiency, honesty, or currency of any advice or
                    content the Service provides.
                  </li>
                  <li>
                    This Service, its servers, content, and e-mails sent from or
                    on behalf of the company unit are free of viruses, scripts,
                    trojan horses, worms, malware, or different harmful
                    elements.
                  </li>
                </ul>
                <li>
                  Some law jurisdictions do not allow the exclusion of positive
                  styles and guarantees and constraints on relevant statutory
                  rights of a client. Therefore, some or all of the prime
                  exclusions and limitations may not use for everyone. Thus, in
                  such a case, the boycott and regulations set this section
                  shall be applied to the most effective extent enforceable at a
                  lower place applicable law.
                </li>
              </ul>
            </ul>
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                Governing Law
              </li>
              <ul>
                <li>
                  The laws of all countries, excluding its conflicts of law
                  rules, shall govern these Terms and use of the Service. Your
                  application benefit may also be subject to local, state,
                  national, or international laws.
                </li>
              </ul>
            </ul>
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                Debate verdict
              </li>
              <ul>
                <li>
                  If you have any issues and disputes about the Service, You
                  have to agree first to try to design the brawl informally by
                  approaching the firm.
                </li>
                <li></li>
                <li></li>
              </ul>
            </ul>
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                For European Union (EU) Users
              </li>
              <ul>
                <li>
                  If You are a European Union buyer, you will get all the
                  benefits from any compulsory board of the law of the country
                  where you live.
                </li>
              </ul>
            </ul>
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                The Legal Compliance
              </li>
              <ul>
                <li>
                  You produce and warrant that (i) You are not located in a
                  country that is subject to the Indian government embargo or
                  that has been designated by the government as a “terrorist
                  supporting” country (ii) You are not recorded on any
                  government list of prohibited or secured parties.
                </li>
              </ul>
            </ul>
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                Severability
              </li>
              <ul>
                <li>
                  Suppose any provision of these Terms is avoided or false. In
                  that case, such a plan will be changed and stop to principal
                  motive of the policy of such intent to the most significant
                  amount achievable under applicable law. The pending program
                  will continue in full force and effect.
                </li>
                <li>
                  Except as provided, the collapse to exert a correct or
                  required work of an obligation under these Terms and
                  conditions will not change a party’s capacity to work such
                  right and need such act at any time after not shall the
                  refusal of a breach constitute a disclaimer of any following
                  violation.
                </li>
              </ul>
            </ul>
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                Adaptation analysis
              </li>
              <ul>
                <li>
                  It may have changed these terms and conditions. We have
                  created available for your all services. You must agree that
                  the original English text shall prevail in the case of a
                  dispute.
                </li>
              </ul>
            </ul>
            <ul>
              <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
                Change of Terms and Conditions
              </li>
              <ul>
                <li>
                  At our control, we reserve the right to adapt or change these
                  company Terms and conditions of all time. If a review is a
                  material, we will make reasonable efforts to provide at least
                  one month’s notice before any new terms take effect. What
                  constitutes will determine a material change at Our sole
                  attention.
                </li>
                <li>
                  Attracting to approach or use our Service after all alteration
                  becomes more crucial. You must agree to be obligated by the
                  changed terms ultimately. If you are not conforming to the new
                  terms, in whole or part, please stop using the website and the
                  Service.
                </li>
              </ul>
            </ul>
          </ul>
        </div>
      </SectionCard>
      {/* Web Seo  Main Component End */}
      {/* FooterSection Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
      {/* FooterSection End */}
    </div>
  );
}

export default TermsAndConditions;
