import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";

export function NavItemsContent({
  iconColor,
  iconDivColor,
  iconName,
  title,
  subTitle,
  LinkList,
}) {
  return (
    <>
      <li>
        <div className="flex flex-col ml-[10px]">
          <div className="flex flex-row gap-5 items-center mb-2">
            <div
              className={`flex flex-row justify-center items-center w-8 h-8 scale-110 rounded-md`}
              style={{
                backgroundColor: `${iconDivColor}`,
              }}
              draggable="false"
            >
              <FontAwesomeIcon icon={iconName} color={`${iconColor}`} />
            </div>

            <div className="flex flex-col">
              <p className="font-montserrat font-semibold text-sm">{title}</p>
              <p className="font-montserrat font-semibold opacity-50 text-xs">
                {subTitle}
              </p>
            </div>
          </div>
          <ul className="list-disc  text-[0.8rem] ml-16 opacity-90">
            {LinkList.map(({ title, link }, index) => (
              <li className="mt-1 font-montserrat font-semibold text-xs hover:cursor-pointer hover:text-[#057aff] w-fit">
                <Link to={link} key={index}>
                  {title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </li>
    </>
  );
}
