import React, { useState, useEffect } from "react";
import "./ContactComponent.css";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FormGroup, Label } from "reactstrap";

function ContactComponent({ padding }) {
  const [fbLeads, setFbLeads] = useState("");

  useEffect(() => {
    // Get the full URL
    const fullUrl = window.location.href;

    // Parse the URL and query parameters
    const url = new URL(fullUrl);
    const params = new URLSearchParams(url.search);

    // Get the 'utm_source' parameter
    const utmSource = params.get("utm_source");

    // Determine the value for fbLeads based on utmSource
    let leadsValue = "";
    switch (utmSource) {
      case "facebook_organic":
        leadsValue = "Fb Organic";
        break;
      case "Google_CPC":
        leadsValue = "Google CPC";
        break;
      case "instagram_organic":
        leadsValue = "Instagram Organic";
        break;
      case "linkedin_organic":
        leadsValue = "Linkedin Organic";
        break;
      case "PPL_DelhiNCR":
        leadsValue = "PPL Delhi NCR";
        break;
      case "Diwali_Offer":
        leadsValue = "Diwali Offer";
        break;
      case "INSTAGRAM_PAID":
        leadsValue = "INSTAGRAM PAID";
        break;
      case "Whatsapp_API":
        leadsValue = "Whatsapp API";
        break;
      case "Bangalore_data":
        leadsValue = "W Bangalore";
        break;
      case "Phamphlet":
        leadsValue = "Phamphlet";
        break;
      case "Deman_Gen":
        leadsValue = "Demand Gen";
        break;
      case "FACEBOOK":
        leadsValue = "Fb";
        break;
      case "EMAIL":
        leadsValue = "EMAIL";
        break;
      case "SMS":
        leadsValue = "SMS";
        break;
      case "Raw_Data":
        leadsValue = "Raw Data";
        break;
      case "Whatsapp_new":
        leadsValue = "Whatsapp";
        break;
      case "Offer":
        leadsValue = "Offer";
        break;
      case "Bings_Ads":
        leadsValue = "Bings Ads";
        break;
      case "Google_PPLJAN":
        leadsValue = "Google PPLJAN";
        break;
      case "Insta_Inpaid":
        leadsValue = "Insta IN";
        break;
      case "FB_Inpaid":
        leadsValue = "FB IN";
        break;
      case "Whatsapp_In":
        leadsValue = "Whatsapp IN";
        break;
      case "Email_In":
        leadsValue = "Email IN";
        break;
      case "SMS_In":
        leadsValue = "SMS IN";
        break;
      default:
        leadsValue = "nextbigbox.io";
    }
    // Set the fbLeads state
    setFbLeads(leadsValue);
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    console.log(data);
    let formData = new FormData();
    formData.append("uname", data.firstName + " " + data.lastName);
    formData.append("email", data.email);
    formData.append("mobile", Number(data.mobile));
    formData.append("utmSource", fbLeads);
    formData.append("servicesType", data.services);
    formData.append("remarks", data.message);
    formData.append("city", data.city);
    // https://nextbigbox.org/nbb.sale-crm/

    const res = await axios.post(
      "https://salescrm.nextbigbox.tech/api/addNewWebsiteLead",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res) {
      showToast("success");
      reset();
    } else {
      showToast("error");
    }
  };
  // toast message
  const showToast = (status) => {
    switch (status) {
      case "success":
        toast.success("Form Submitted Successfully.", {
          position: "top-right",
          autoClose: 1000, // Close the toast after 1 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      case "error":
        toast.error("Something Went Wrong.", {
          position: "top-right",
          autoClose: 1000, // Close the toast after 1 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      default:
    }
  };

  return (
    <div
      id="contact"
      className={`main_contact_component ${
        !padding ? " md:px-[5.3rem] md:py-[4rem]" : ""
      }`}
    >
      <div className="main_contact_component_left">
        <h4 className="font-proximaNovaExtraBold mt-12">Let's Contact Us!</h4>
        {/* <div className="ml-2 mt-1">
          <span>Email us at : </span> <a href="mailto:reachus@nextbigbox.io">reachus@nextbigbox.io</a>
        </div> */}
        <video
          autoPlay
          loop
          muted
          className="md:-ml-68 md:mt-12 ContactTV"
          data-aos="fade-right"
        >
          <source src={`${process.env.PUBLIC_URL}tv.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="main_contact_component_right"
        noValidate
      >
        <FormGroup className="half">
          <Label htmlFor="first_name">First Name</Label>
          <input
            type="text"
            id="first_name"
            placeholder="Enter Your First Name"
            {...register("firstName", { required: true })}
            aria-invalid={errors.firstName ? "true" : "false"}
          />
          {errors.firstName?.type === "required" && (
            <p role="alert" className="form-error">
              First Name is required
            </p>
          )}
        </FormGroup>{" "}
        <FormGroup className="half">
          <Label htmlFor="last_name">Last Name</Label>
          <input
            type="text"
            id="last_name"
            placeholder="Enter Your Last Name"
            {...register("lastName", { required: true })}
            aria-invalid={errors.lastName ? "true" : "false"}
          />
          {errors.lastName?.type === "required" && (
            <p role="alert" className="form-error">
              Last Name is required.
            </p>
          )}
        </FormGroup>
        <FormGroup className="half">
          <Label htmlFor="mobile_number">Mobile</Label>
          <input
            type="text"
            id="mobile_number"
            placeholder="Enter Your Mobile"
            {...register("mobile", {
              required: "Mobile number is required",
              minLength: {
                value: 10,
                message: "Mobile number should be at least 10 digits",
              },
              maxLength: {
                value: 10,
                message: "Mobile number should be at most 10 digits",
              },
            })}
            aria-invalid={errors.mobile ? "true" : "false"}
          />
          {errors.mobile && (
            <p role="alert" className="form-error">
              {errors.mobile.message}
            </p>
          )}
        </FormGroup>
        <FormGroup className="half">
          <Label htmlFor="email_id">Email</Label>
          <input
            type="email"
            id="email_id"
            placeholder="Enter Your Email"
            {...register("email", { required: true })}
            aria-invalid={errors.email ? "true" : "false"}
          />
          {errors.email?.type === "required" && (
            <p role="alert" className="form-error">
              Email is required.
            </p>
          )}
        </FormGroup>
        <FormGroup className="half">
          <Label htmlFor="project_type">Services</Label>
          <select
            id="project_type"
            {...register("services", { required: true })}
            aria-invalid={errors.services ? "true" : "false"}
          >
            <option value={""} disabled>
              Choose Service Type
            </option>
            <option value={"Web Development"}> Web Development</option>
            <option value={"Software Development"}>
              {" "}
              Software Development
            </option>
            <option value={"Fintech Services"}> Fintech Services</option>
            <option value={"Social Media"}> Social Media</option>
            <option value={"SEO"}>SEO</option>
          </select>
          {errors.services?.type === "required" && (
            <p role="alert" className="form-error">
              Services is required.
            </p>
          )}
        </FormGroup>
        <FormGroup className="half">
          <Label>City</Label>
          <input
            type="text"
            placeholder=""
            {...register("city", { required: true })}
            aria-invalid={errors.city ? "true" : "false"}
          />
          {errors.city?.type === "required" && (
            <p role="alert" className="form-error">
              City is required.
            </p>
          )}
        </FormGroup>
        <FormGroup className="full">
          <Label>Message</Label>
          <textarea
            rows="2"
            cols="5"
            placeholder="Type Your Message..."
            style={{ width: "98%" }}
            {...register("message", { required: true })}
            aria-invalid={errors.message ? "true" : "false"}
          />
          {errors.message?.type === "required" && (
            <p role="alert" className="form-error">
              Message is required.
            </p>
          )}
        </FormGroup>
        <FormGroup className="full submit mt-6">
          <input type="submit" placeholder="SUMBIT" />
        </FormGroup>
      </form>
    </div>
  );
}

export default ContactComponent;
