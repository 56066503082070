import React, { useEffect, useState } from "react";
import "./Navbar.css";
import LogoBlack from "../../media/NBB-LOGO-01-BLACK.png";
import LogoWhite from "../../media/NBB-LOGO-01-white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faClose,
  faNavicon,
  faMagnifyingGlass,
  faHashtag,
  faHandPointer,
  faCode,
  faListCheck,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";

import CRM_ICON from "../../media/crm.png";
import MAIL_ICON from "../../media/mail.png";
import ECOMMERCE_ICON from "../../media/ecommerce.png";
import ADS_ICON from "../../media/ads.png";
import WEB_ICON from "../../media/web-programming.png";
import APP from "../../media/app.png";
import SOCIAL_MEDIA from "../../media/social-media.png";
import SEO from "../../media/search-engine-optimisation.png";
import API from "../../media/api.png";
import FINTECH from "../../media/fintech.png";
import { Link } from "react-router-dom";
import { NavItems } from "./NavItem/NavItems";
import { NavItemsContent } from "./NavItem/NavItemsContent";

function Navbar({ isNavbarOpened, isHomePage }) {
  const [openNav, setOpenNav] = useState(false);
  const [navItemOpened, setNavItemOpened] = useState(false);
  const [navBackground, setNavBackground] = useState(false); // default state was false, intetionall made it true for testing
  const navigationHandler = () => {
    setOpenNav((prev) => !prev);
    isNavbarOpened(openNav);
    setNavBackground((prev) => !prev);
  };

  const toggleNavItemOpened = () => {
    setNavItemOpened((prev) => !prev);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setNavBackground(true);
      } else {
        setNavBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures that this effect runs only once after the component mounts

  return (
    <>
      <div
        className={`p-[5.3rem] pt-3 pb-3 ${
          navBackground
            ? "bg-white text-black"
            : isHomePage
            ? "bg-transparent text-white"
            : "bg-black text-white"
        } flex justify-between myNav`}
      >
        <ul className="flex justify-start gap-2 pl-5 font-montserrat  text-base items-center myNav_item ">
          <li className="mobile_nav justify-between">
            <Link to={"/"}>
              <img
                src={navBackground ? LogoBlack : LogoWhite}
                alt="NBB_LOGO"
                className="w-28"
                width={"112px"}
                height={"48"}
                draggable="false"
              />
            </Link>
          </li>
        </ul>
        {/* font-oswald font-light text-base gap-2 */}
        <ul className="flex justify-start pr-5 gap-2 font-montserrat font-semibold text-base items-center myNav_item myNav_item_Right">
          {/* Start Here - Experimental Tab*/}
          <div className="group1">
            <li className=" navItem  pl-2 pr-2 ml-3  " aria-label="true">
              Services{" "}
              <FontAwesomeIcon
                icon={faChevronDown}
                className="navDropIcon text-xs ml-1"
              />
            </li>
            <div className="navContent mt-2">
              <div>
                <p className=" font-montserrat font-semibold text-black mb-6">
                  All Services
                </p>
                <ul>
                  <ul className="service_container-2 flex flex-wrap gap-8">
                    {/* for SEO */}
                    <NavItemsContent
                      iconName={faMagnifyingGlass}
                      iconDivColor={"#d0dce5"}
                      iconColor={"#16537E"}
                      title={"Search Engine Optimization"}
                      subTitle={"Improved Your Visibility in Google Search"}
                      LinkList={[
                        {
                          title: "Local SEO",
                          link: "/local-seo-services",
                        },
                        {
                          title: "E-commerce SEO",
                          link: "/ecommerce-seo-services",
                        },
                        {
                          title: "Web SEO",
                          link: "/search-engine-optimization",
                        },
                        {
                          title: "Enterprise SEO",
                          link: "/enterprise-seo-services",
                        },
                      ]}
                    />
                    {/* Social Media Marketing */}
                    <NavItemsContent
                      iconName={faHashtag}
                      iconDivColor={"#fcd9d6"}
                      iconColor={"#f44336"}
                      title={"Social Media Marketing"}
                      subTitle={
                        "Maxmize Efficiency, Minimize cost with Social Media"
                      }
                      LinkList={[
                        {
                          title: "LinkedIn Marketing",
                          link: "/linkedin-marketing-services",
                        },
                        {
                          title: "Social Media Marketing",
                          link: "/social-media-marketing-services",
                        },
                        {
                          title: "Email marketing",
                          link: "/email-marketing-services",
                        },
                      ]}
                    />
                    {/* for Sales CRM */}
                    <NavItemsContent
                      iconName={faChartSimple}
                      iconDivColor={"#cce5e5"}
                      iconColor={"#008080"}
                      title={"Sales CRM"}
                      subTitle={"Boost Sales with Smart CRM Solutions"}
                      LinkList={[
                        {
                          title: "Fintech CRM",
                          link: "/fintech-crm",
                        },
                        {
                          title: "Sales CRM",
                          link: "/sales-crm",
                        },
                        {
                          title: "Fintech Solution",
                          link: "/fintech-solution",
                        },
                      ]}
                    />
                    {/* for Web Development */}
                    <NavItemsContent
                      iconName={faCode}
                      iconDivColor={"#e5cce5"}
                      iconColor={"#800080"}
                      title={"Web Development & Design"}
                      subTitle={"Transforming Ideas into Beautiful Websites"}
                      LinkList={[
                        {
                          title: "Web Development",
                          link: "/web-development",
                        },
                        {
                          title: "API Integration",
                          link: "/api-integration",
                        },
                      ]}
                    />
                    {/* for Management System */}
                    <NavItemsContent
                      iconName={faListCheck}
                      iconDivColor={"#f7edd2"}
                      iconColor={"#DAA520"}
                      title={"Management System"}
                      subTitle={"Simplify Operations with Effective Management"}
                      LinkList={[
                        {
                          title: "Loan Management System",
                          link: "/loan-crm",
                        },
                        {
                          title: "HRMS",
                          link: "/hrms",
                        },
                      ]}
                    />
                    {/* PPc Ads */}
                    <NavItemsContent
                      iconName={faHandPointer}
                      iconDivColor={"#e1eddb"}
                      iconColor={"#6AA84F"}
                      title={"Pay-Per-Click"}
                      subTitle={"Maxmize ROI with Precision PPC Campaigns"}
                      LinkList={[
                        {
                          title: "PPC Ads",
                          link: "/ppc-ads-services",
                        },
                        {
                          title: "Google Ads",
                          link: "/google-ads-services",
                        },
                      ]}
                    />
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          {/* <NavItems title={"Head Section"} /> */}

          {/* Ends Here - Experimental Tab*/}

          {/* All Services Starts */}
          {/* <div className="group1">
            <li className=" navItem  pl-2 pr-2 ml-3  ">
              Solutions{" "}
              <FontAwesomeIcon
                icon={faChevronDown}
                className="navDropIcon text-xs ml-1"
              />
            </li>
            <div className="navContent mt-2">
              <div>
                <p className=" font-oswald text-black text-6xl mb-4">
                  Our Solutions
                </p>
                <ul>
                  <ul className="service_container">
                    <Link to={"/fintech-crm"}>
                      <li>
                        {" "}
                        <img
                          src={CRM_ICON}
                          alt="crm_icon"
                          className="w-7 h-7"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Fintech CRM</li>
                          <li>Elevate Connections with Our Fintech CRM</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/sales-crm"}>
                      <li>
                        {" "}
                        <img
                          src={SEO}
                          alt="crm_icon"
                          className="w-7 h-7"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Sales CRM</li>
                          <li>Fueling Growth Through Sales CRM</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/loan-crm"}>
                      <li>
                        {" "}
                        <img
                          src={ECOMMERCE_ICON}
                          alt="e-commerce_icon"
                          className="w-7 h-7"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">
                            Loan Management System
                          </li>
                          <li>Excel you Loan management with Curated LMS</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/hrms"}>
                      <li>
                        {" "}
                        <img
                          src={ADS_ICON}
                          alt="e-commerce_icon"
                          className="w-7 h-7"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">HRMS</li>
                          <li>Maximize Efficiency, Minimize Cost With HRMS</li>
                        </ul>
                      </li>
                    </Link>
                  </ul>
                  <ul className="service_container">
                    <Link to={"/web-development"}>
                      <li className="">
                        {" "}
                        <img
                          src={WEB_ICON}
                          alt="web_development_icon"
                          className="w-7 h-7 "
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Web Development</li>
                          <li>
                            Crafting Digital Experiences with Innovative Web
                            Solutions
                          </li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/api-integration"}>
                      <li className="mt-2">
                        {" "}
                        <img
                          src={API}
                          alt="app_development_icon"
                          className="w-7 h-7"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">API Integration</li>
                          <li>
                            Automate you business with our API Integration
                          </li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/fintech-solution"}>
                      <li>
                        {" "}
                        <img
                          src={FINTECH}
                          alt="social_media_icon"
                          className="w-7 h-7 opacity-70"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Fintech Solution</li>
                          <li>We support your fintech services</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/email-marketing-services"}>
                      <li>
                        {" "}
                        <img
                          src={ECOMMERCE_ICON}
                          alt="e-commerce_icon"
                          className="w-7 h-7"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Email Marketing</li>
                          <li>Excel your Email Marketing Campaigns</li>
                        </ul>
                      </li>
                    </Link>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          <div className="group1">
            <li className=" navItem pl-1 pr-2 ml-3">
              Services{" "}
              <FontAwesomeIcon
                icon={faChevronDown}
                className=" navDropIcon text-xs ml-1"
              />
            </li>
            <div className="navContent mt-2">
              <div>
                <p className=" font-oswald text-black text-6xl mb-4">
                  Our Services
                </p>
                <ul>
                  <ul className="service_container">
                    <Link to={"/ppc-ads-services"}>
                      <li>
                        {" "}
                        <img
                          src={CRM_ICON}
                          alt="crm_icon"
                          className="w-7 h-7"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">PPC Ads</li>
                          <li>Maximize ROI with Precision PPC Campaigns</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/google-ads-services"}>
                      <li>
                        {" "}
                        <img
                          src={MAIL_ICON}
                          alt="email_icon"
                          className="w-7 h-7"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Google Ads</li>
                          <li>Boost Your Brand with Google Ads Success</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/linkedin-marketing-services"}>
                      <li>
                        {" "}
                        <img
                          src={ECOMMERCE_ICON}
                          alt="e-commerce_icon"
                          className="w-7 h-7"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">
                            LinkedIn Marketing
                          </li>
                          <li>
                            Excel you LinkedIn Marketing with great Strategy
                          </li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/social-media-marketing-services"}>
                      <li>
                        {" "}
                        <img
                          src={ADS_ICON}
                          alt="e-commerce_icon"
                          className="w-7 h-7"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">
                            Social Media Marketing
                          </li>
                          <li>
                            Maximize Efficiency, Minimize Cost With Social Media
                          </li>
                        </ul>
                      </li>
                    </Link>
                  </ul>
                  <ul className="service_container">
                    <Link to={"/search-engine-optimization"}>
                      <li className="">
                        <img
                          src={WEB_ICON}
                          alt="web_development_icon"
                          className="w-7 h-7 "
                          draggable="false"
                        />

                        <ul>
                          <li className="service-heading">Web SEO</li>
                          <li>Improved Your Visibility in Google Search</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/local-seo-services"}>
                      <li className="mt-2">
                        {" "}
                        <img
                          src={APP}
                          alt="app_development_icon"
                          className="w-7 h-7"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Local SEO</li>
                          <li>Maximize Impact With local SEO</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/ecommerce-seo-services"}>
                      <li>
                        {" "}
                        <img
                          src={SOCIAL_MEDIA}
                          alt="social_media_icon"
                          className="w-7 h-7 "
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">E-Commerce SEO</li>
                          <li>
                            Driving Sales, Building Brands: E-Commerce SEO
                          </li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/enterprise-seo-services"}>
                      <li>
                        {" "}
                        <img
                          src={SEO}
                          alt="seo_icon"
                          className="w-7 h-7"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Enterprise SEO</li>
                          <li>
                            Fueling Growth Through Targeted Paid Campaigns
                          </li>
                        </ul>
                      </li>
                    </Link>
                  </ul>
                </ul>
              </div>
            </div>
          </div> */}

          {/* All Services Ends */}
          <li
            className=" portfolio  pl-2 pr-2 cursor-pointer"
            aria-label="true"
          >
            <Link to={"/portfolio"}>Portfolio</Link>
          </li>
          <li className="navItem  pl-2 pr-2 " aria-label="true">
            <Link to={"/about"}>Who we are</Link>
          </li>
          <li className="navItem  pl-2 pr-2 " aria-label="true">
            <Link to={"https://www.nextbigbox.io/blogs/"} target="blank">
              Blogs
            </Link>
          </li>
          <li className="navItem  pl-2 pr-2 " aria-label="true">
            <Link to={"/contact"}>Get in touch</Link>
          </li>
        </ul>
        <ul
          className={`mobile_nav_right ${
            navBackground ? "bg-transparent" : "bg-transparent"
          }`}
        >
          <FontAwesomeIcon
            icon={faNavicon}
            className={`${!openNav ? "visible" : "hidden"}`}
            onClick={navigationHandler}
            color="black"
          />
          <FontAwesomeIcon
            icon={faClose}
            className={`${openNav ? "visible" : "hidden"}`}
            onClick={navigationHandler}
          />
        </ul>
      </div>
      <div
        className={`${
          openNav
            ? " p-1 pt-20 pb-3 bg-PureWhite flex justify-between r_myNav_item "
            : " p-1 pt-3 pb-3 bg-PureWhite flex justify-between r_myNav m_myNav"
        }`}
      >
        <ul
          className={`ml-2 ${
            openNav
              ? "flex justify-start gap-2 pl-5 font-oswald font-light text-base items-center r_myNav_item"
              : "flex justify-start gap-2 pl-5 font-oswald font-light text-base items-center  m_item "
          }`}
        >
          <div className="r_group">
            <li
              className=" navItem  pl-1 pr-2 ml-3 m_navItem"
              onClick={toggleNavItemOpened}
            >
              Services{" "}
              <FontAwesomeIcon
                icon={faChevronDown}
                className={` navDropIcon text-xs ml-1 ${
                  navItemOpened ? "rotate" : ""
                }`}
              />
            </li>
            <div
              className={`border-l-2 m_navContent b ${
                navItemOpened ? "navItemOpened" : ""
              }`}
              onClick={toggleNavItemOpened}
            >
              <div className="m_services">
                <p className=" font-oswald text-black text-2xl mb-1">
                  Our Services
                </p>
                <ul>
                  <ul className="service_container">
                    <Link to={"/ppc-ads-services"}>
                      <li>
                        {" "}
                        <img
                          src={ADS_ICON}
                          alt="crm_icon"
                          className="w-6 h-6"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">PPC Ads</li>
                          <li>One Stop Solution</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/google-ads-services"}>
                      <li>
                        <img
                          src={MAIL_ICON}
                          alt="email_icon"
                          className="w-6 h-6"
                          draggable="false"
                        />
                        <ul>
                          <li>Google Ads</li>
                          <li>Elavate Business</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/linkedin-marketing-services"}>
                      <li>
                        {" "}
                        <img
                          src={ECOMMERCE_ICON}
                          alt="e-commerce_icon"
                          className="w-6 h-6"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">
                            Linkedin Marketing
                          </li>
                          <li>Widen Your Reach</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/social-media-marketing-services"}>
                      <li>
                        {" "}
                        <img
                          src={ADS_ICON}
                          alt="e-commerce_icon"
                          className="w-6 h-6"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">SMM</li>
                          <li>Maximize Efficiency</li>
                        </ul>
                      </li>
                    </Link>
                  </ul>
                  <ul className="service_container">
                    <Link to={"/web-seo"}>
                      <li className="">
                        {" "}
                        <img
                          src={WEB_ICON}
                          alt="web_development_icon"
                          className="w-6 h-6 "
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Web SEO</li>
                          <li>Improve Visibility</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/search-engine-optimizaion"}>
                      <li className="mt-2">
                        {" "}
                        <img
                          src={APP}
                          alt="app_development_icon"
                          className="w-6 h-6"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Local SEO</li>
                          <li>Easy Conversion.</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/ecommerce-seo-services"}>
                      <li>
                        {" "}
                        <img
                          src={SOCIAL_MEDIA}
                          alt="social_media_icon"
                          className="w-6 h-6 "
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">E-Commerce SEO</li>
                          <li>Driving Sales.</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/enterprise-seo-services"}>
                      <li>
                        {" "}
                        <img
                          src={SEO}
                          alt="seo_icon"
                          className="w-6 h-6"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Enterprise SEO</li>
                          <li>Targeted Campaigns.</li>
                        </ul>
                      </li>
                    </Link>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          <div className="r_group">
            <li
              className=" navItem  pl-1 pr-2 ml-3 m_navItem mt-4"
              onClick={toggleNavItemOpened}
            >
              Solution{" "}
              <FontAwesomeIcon
                icon={faChevronDown}
                className={` navDropIcon text-xs ml-1 ${
                  !navItemOpened ? "rotate" : ""
                }`}
              />
            </li>
            <div
              className={`border-l-2 m_navContent ${
                !navItemOpened ? "navItemOpened" : ""
              }`}
            >
              <div className="m_solution">
                <p className=" font-oswald text-black text-2xl mb-1 ">
                  Our Solutions
                </p>
                <ul>
                  <ul className="service_container">
                    <Link to={"/fintech-crm"}>
                      <li>
                        {" "}
                        <img
                          src={CRM_ICON}
                          alt="crm_icon"
                          className="w-6 h-6"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Fintech CRM</li>
                          <li>One Stop Solution.</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/sales-crm"}>
                      <li>
                        <img
                          src={SEO}
                          alt="crm_icon"
                          className="w-6 h-6"
                          draggable="false"
                        />
                        <ul>
                          <li>Sales CRM</li>
                          <li>Elavate Business.</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/loan-crm"}>
                      <li>
                        {" "}
                        <img
                          src={ECOMMERCE_ICON}
                          alt="e-commerce_icon"
                          className="w-6 h-6"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Loan Management</li>
                          <li>Easy Disbursal</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/hrms"}>
                      <li>
                        {" "}
                        <img
                          src={ADS_ICON}
                          alt="e-commerce_icon"
                          className="w-6 h-6"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">HRMS</li>
                          <li>Maximize Efficiency.</li>
                        </ul>
                      </li>
                    </Link>
                  </ul>
                  <ul className="service_container">
                    <Link to={"/web-development"}>
                      <li className="">
                        {" "}
                        <img
                          src={WEB_ICON}
                          alt="web_development_icon"
                          className="w-6 h-6 "
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Web Development</li>
                          <li>Innovating Ideas.</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/api-integration"}>
                      <li className="mt-2">
                        {" "}
                        <img
                          src={APP}
                          alt="app_development_icon"
                          className="w-6 h-6"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Api Integration</li>
                          <li>Maximize Impact.</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/fintech-solution"}>
                      <li>
                        {" "}
                        <img
                          src={SOCIAL_MEDIA}
                          alt="social_media_icon"
                          className="w-6 h-6 "
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Fintech Solution</li>
                          <li>Driving Sales.</li>
                        </ul>
                      </li>
                    </Link>
                    <Link to={"/email-marketing-services"}>
                      <li>
                        {" "}
                        <img
                          src={SEO}
                          alt="seo_icon"
                          className="w-6 h-6"
                          draggable="false"
                        />
                        <ul>
                          <li className="service-heading">Email Marketing</li>
                          <li>Targeted Campaigns.</li>
                        </ul>
                      </li>
                    </Link>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          <li className=" r_portfolio  pl-1 pr-2 cursor-pointer">
            <Link to={"/portfolio"}>Portfolio</Link>
          </li>
        </ul>
        <ul className="flex justify-start ml-2 gap-2  font-oswald font-light text-base items-center r_myNav_item b_myNav_item ">
          <li className="navItem  mt-2 ">
            <Link to={"/about"}>Who we are</Link>
          </li>
          <li className="navItem  mt-2 ">
            <Link to={"https://www.nextbigbox.io/blogs/"} target="blank">
              Blogs
            </Link>
          </li>
          <li className="navItem  mt-2 m_contact">
            <Link to="/contact">
              Contact Us :<span className="text-[.9rem]">1800-123-120555</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navbar;
