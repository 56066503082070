import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export function ThankYouPage(props) {
  const nav = useNavigate();
  const goBack = () => {
    nav(-1);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <div className="mx-auto flex flex-row justify-center items-center h-[10rem] w-[10rem] rounded-full bg-green-200">
          <FontAwesomeIcon icon={faCheck} size="7x" color="green" />
        </div>
        <h1 className="text-[3.5rem] md:text-[4.5rem] font-proximaNovaExtraBold">
          Thank You!
        </h1>
        <p className="font-montserrat text-base mb-8">
          Form has been submitted successfully. We’ll get back to you shortly.
        </p>
        <button
          className="font-montserrat font-semibold text-white hover:cursor-pointer bg-blue-500 px-4 py-2 rounded-md hover:bg-blue-600 "
          onClick={goBack}
        >
          Return to Website
        </button>
      </div>
    </div>
  );
}
