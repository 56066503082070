import React, { useEffect } from "react";
import "./PortFolioPageContent.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MANTRALAY_LOGO from "../../media/mantralay_logo.jpg";
import MANTRALAY_BRANDING from "../../media/mantralay_branding.jpg";
import WEB_DEVELOPMENT from "../../media/web_devlopment.webp";
import APP_DEVELOPMENT from "../../media/mobile_app.webp";
import PHONE from "../../media/phone.svg";
import DOCUMETATION from "../../media/documentation.svg";
import ONBOARDING from "../../media/onboarding.svg";
import PROCESS from "../../media/business_process.svg";
import MIGRATION from "../../media/migration.svg";
import WAWY_ARROW from "../../media/wawy_arrow.png";
import LMS from "../../media/portfolio_lead_management_system.svg";
import SALES_CRM from "../../media/sales-crm.png";
import SOCIAL_CREATIVE from "../../media/creative_agency.webp";
import BUSINESS_MANAGEMENT from "../../media/business-management.png";
// import HRMS_SECTION from '../../media/hrms-sample.svg'
import social_mobile from "../../media/mobile.webp";

import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import { Link } from "react-router-dom";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
gsap.registerPlugin(ScrollTrigger);

function PortFolioPageComponent({ test }) {
  // for animation
  useEffect(() => {
    //heading
    gsap.from(".title", { y: -50, opacity: 0 });
    gsap.to(".title", { y: 0, opacity: 1, duration: 1 });
    // fromLeft
    gsap.from(".fromLeft", { x: -100, opacity: 0 });
    gsap.to(".fromLeft", { x: 0, opacity: 1, duration: 1 });
    // fromBottomRight
    // gsap.from(".fromBottomRight", { x: 100, y: 100, opacity: 0 });
    // gsap.to(".fromBottomRight", { x: 0, y: 0, opacity: 1, duration: 2 });
    //fromBottom1sd1
    gsap.from(".fromBottom1sd1", { y: 200, opacity: 0 });
    gsap.to(".fromBottom1sd1", { y: 0, opacity: 1, duration: 1, delay: 1 });
    // fromBottom
    gsap.from(".fromBottom", { y: 200, opacity: 0 });
    gsap.to(".fromBottom", { y: 0, opacity: 1, duration: 1.5 });
    // fromBottom1s
    gsap.from(".fromBottom1s", { y: 200, opacity: 0 });
    gsap.to(".fromBottom1s", { y: 0, opacity: 1, duration: 1 });
    //fromLeft1sd1
    gsap.from(".fromRight1sd1", { x: 1000, opacity: 0 });
    gsap.to(".fromRight1sd1", { x: 0, opacity: 1, duration: 1, delay: 0 });
    //fromLeft1sd2
    gsap.from(".fromRight1sd2", { x: 1000, opacity: 0 });
    gsap.to(".fromRight1sd2", { x: 0, opacity: 1, duration: 1, delay: 1 });
    //fromLeft1sd3
    gsap.from(".fromRight1sd3", { x: 1000, opacity: 0 });
    gsap.to(".fromRight1sd3", {
      x: 0,
      opacity: 1,
      duration: 1,
      delay: 2,
      ease: "ease",
    });
    gsap.from(".portfolio1imgfromleft", {
      scrollTrigger: {
        trigger: ".portfolio1imgfromleft",
        scrub: false,
        start: "top bottom",
        emd: "bottom 20%",
        toggleActions: "play reverse play reverse",
      },
      x: -100,
      opacity: 0,
    });
    gsap.to(".portfolio1imgfromleft", {
      scrollTrigger: {
        trigger: ".portfolio1imgfromleft",
        scrub: false,
        start: "top bottom",
        emd: "bottom -100%",
        toggleActions: "play reverse play reverse",
      },
      x: 0,
      opacity: 1,
      duration: 1,
    });
    // scroll portfolio1imgfromrright
    gsap.from(".portfolio1imgfromrright", {
      scrollTrigger: {
        trigger: ".portfolio1imgfromrright",
        scrub: false,
        start: "top bottom",
        emd: "bottom -100%",
        toggleActions: "play reverse play reverse",
      },
      x: 100,
      opacity: 0,
    });
    gsap.to(".portfolio1imgfromrright", {
      scrollTrigger: {
        trigger: ".portfolio1imgfromrright",
        scrub: false,
        start: "top bottom",
        emd: "bottom -100%",
        toggleActions: "play reverse play reverse",
      },
      x: 0,
      opacity: 1,
      duration: 1,
    });
  }, []);
  const DemoCtaText =
    "We transform your dream of streamlining your business online into reality. Let’s dive into the world of IT and digital marketing solutions with our expertise.";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 3600;
  } else {
    scrollValue = 5950;
  }

  return (
    <div className="portfolio_page_content">
      <div className="portfolio_page_content_hero bg-transparent">
        <section className="portfolio_section section2">
          <div className="left">
            <h1 className="title">Web Development</h1>
            <div>
              <p>
                Empower your business with our digital agency's expertise. We
                craft stunning websites, boost online presence
              </p>
            </div>
            <div>
              <p>
                Transform your ideas into innovative apps with our app
                development expertise. We create user-friendly, high-performance
                mobile and web applications for your success.
              </p>
            </div>
          </div>
          <div className="right">
            <img
              className="fromBottom1s"
              src={WEB_DEVELOPMENT}
              alt="social_img"
            />
          </div>
        </section>
        <CtaComponent
          description={DemoCtaText}
          scrollVal={scrollValue}
          scrollDuration={100}
        />
        <section className="portfolio_section section2">
          <div className="right portfolio1imgfromleft">
            <img
              className="fromBottom1s"
              src={APP_DEVELOPMENT}
              alt="social_img"
              draggable="false"
            />
          </div>
          <div className="left portfolio1imgfromrright">
            <h1 className="title font-serif ">App Development</h1>
            <div>
              <p>
                Empower your business with our digital agency's expertise. We
                craft stunning websites, boost online presence
              </p>
            </div>
            <div>
              <p>
                Transform your ideas into innovative apps with our app
                development expertise. We create user-friendly, high-performance
                mobile and web applications for your success.
              </p>
            </div>
          </div>
        </section>
        <section className="portfolio_section section1">
          <div className="left">
            <h1 className="title">Logo's & Branding</h1>
            <div>
              <h2 className="fromLeft">
                lOGO <br /> CREATIVE BRANDING MARKETING
              </h2>
              <p className="fromBottom1s">
                <img
                  src={SOCIAL_CREATIVE}
                  className=""
                  alt="img"
                  draggable="false"
                />
              </p>
            </div>
            <h2 className="fromBottom">Online Presence</h2>
            <p className="fromBottom1sd1">
              <img src={BUSINESS_MANAGEMENT} alt="img" draggable="false" />
            </p>
          </div>
          <div className="right">
            <div className="upper">
              <img
                className="fromBottom1s"
                src={MANTRALAY_LOGO}
                alt="social_img"
                draggable="false"
              />
              <img
                className="fromBottom1s"
                src={MANTRALAY_BRANDING}
                alt="social_img"
                draggable="false"
              />
            </div>
            <div className="lower mt-12">
              <img
                className="fromRight1sd1 ml-8"
                src={social_mobile}
                alt="social_img"
                draggable="false"
              />
              <img
                className="fromRight1sd2 ml-4"
                src={social_mobile}
                alt="social_img"
                draggable="false"
              />
              <img
                className="fromRight1sd3 ml-4"
                src={social_mobile}
                alt="social_img"
                draggable="false"
              />
            </div>
          </div>
        </section>
        <section className="portfolio_support_section">
          <h2 className="support_we_provide support_we_provide_up font-proximaNovaExtraBold">
            We are there for you at every step
          </h2>
          <div>
            <div className="support_card support_we_provide_card_rotate">
              <img src={PHONE} alt="img" draggable="false" />
              <p>Phone & Email Support</p>
            </div>
            <div className="support_card support_we_provide_card_rotate">
              <img src={DOCUMETATION} alt="img" draggable="false" />
              <p>Help Documentation & Training</p>
            </div>
            <div className="support_card support_we_provide_card_rotate">
              <img src={ONBOARDING} alt="img" draggable="false" />
              <p>Onboarding</p>
            </div>
            <div className="support_card support_we_provide_card_rotate">
              <img src={PROCESS} alt="img" draggable="false" />
              <p>Business Process Mapping</p>
            </div>
            <div className="support_card support_we_provide_card_rotate">
              <img src={MIGRATION} alt="img" draggable="false" />
              <p>Migration</p>
            </div>
          </div>
        </section>
        <section className="portfolio_crm_section">
          <div className="lead_management_system">
            <div className="lead_management_system_left portfolio_lms_from_left">
              <h2 className="font-proximaNovaExtraBold">Loan Management</h2>
              <p>
                From capturing to qualifying, tracking activities, and routing,
                gain full control of your sales leads with LeadSquared’s Sales
                CRM.{" "}
              </p>
              <div>
                <Link to={"/loan-crm"}>
                  <p>Explore Loan Management System</p>
                  <img src={WAWY_ARROW} alt="img" draggable="false" />
                </Link>
              </div>
            </div>
            <div className="lead_management_system_right portfolio_lms_from_right">
              <img src={LMS} alt="img" draggable="false" />
            </div>
          </div>
          <div className="fintech_crm flex">
            <div className="lead_management_system_right portfolio_sales_from_left">
              <img src={SALES_CRM} alt="img" draggable="false" />
            </div>
            <div className="lead_management_system_left portfolio_sales_from_right">
              <h2 className="font-proximaNovaExtraBold">Sales CRM</h2>
              <p>
                Get a tight grip on your sales process. Monitor every aspect of
                your sales – leads, funnel, salespeople, and revenue. Know
                exactly where the leads are in your pipeline, track their
                movement down the sales funnel, sales closures, and more. The
                cloud-based sales management CRM also helps you access and
                update data (based on sales activity) on the go.
              </p>
              <div>
                <Link to={"/sales-crm"}>
                  <p>Explore Sales CRM</p>
                  <img src={WAWY_ARROW} alt="img" draggable="false" />
                </Link>
              </div>
            </div>
          </div>
        </section>
        <ContactComponent />
      </div>
    </div>
  );
}

export default PortFolioPageComponent;
