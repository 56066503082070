import React from "react";
import "./HomeBanner.css";
import HomePageSideImage1 from "../../media/nbb-marketing.png";
import HomePageSideImage2 from "../../media/nextbigbox_hero.webp";
import WebBanner from "../../media/web-banner.webp";
function HomeBanner() {
  return (
    <div className="hero_banner">
      <div className="w-fit lg:w-[60%] p-3 mt-[10rem] md:mt-[6rem] ml-[2rem] lg:p-0 lg:ml-[6rem] lg:mt-[10rem]">
        <h1 className="text-[3.5rem] lg:text-7xl md:text-6xl leading-[2.9rem] font-proximaNovaExtraBold text-white md:leading-[3.8rem] mb-3">
          Elevate Your Reach with Expert Marketing
        </h1>
        <p className="text-white text-[1rem] lg:text-[1.1rem] font-montserrat">
          Unleash Your Potential with Cutting-Edge Marketing and IT Solutions.
        </p>
      </div>
    </div>
  );
}
{
  /* <div className="hero_banner">
  <div className="w-fit p-3 mt-[6rem] ml-[2rem] lg:p-0 lg:ml-[6rem] lg:mt-[10rem]">
    <h1 className="text-6xl font-montserrat text-white">Next Level Growth</h1>
    <p className="text-white font-montserrat">
      Unleash Your Potential with Cutting-Edge Marketing and IT Solutions.
    </p>
  </div>
</div>; */
}

export default HomeBanner;
