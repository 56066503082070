import React from "react";
import { Link } from "react-router-dom";
import "./NewsAndEventsContent.css";
import news_events from "../../media/news_events_hero.webp";
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import NewsCard from "../CommonComponents/Cards/NewsCard";
import ImageGallery from "../CommonComponents/ImageGallery/ImageGallery";
import gal1 from "../../media/gal1.jpg";
import gal2 from "../../media/gal2.jpg";
import gal3 from "../../media/gal3.jpg";
import gal4 from "../../media/gal4.jpg";
import gal5 from "../../media/gal5.jpg";
import gal6 from "../../media/gal6.jpg";
import gal7 from "../../media/gal7.jpg";
import gal8 from "../../media/gal8.jpg";
import news1 from "../../media/news-01.webp";
import news2 from "../../media/news-02.webp";
import news3 from "../../media/news-03.webp";
import news4 from "../../media/news-04.webp";
import news5 from "../../media/news-05.webp";
import blackArrow from "../../media/black-arrow.png";
import SectionCard from "../CommonComponents/SectionCard";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";

function NewsAndEventsContent() {
  const image1 = [gal1, gal2, gal3, gal4];
  const image2 = [gal5, gal6, gal7, gal8];
  const DemoCtaText =
    "Together, we make your digital dreams a reality. Dive into the world of unmatched IT and digital marketing   solutions with us!";
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    newWindow.opener = null; // For security
  };

  const news = [
    {
      image: news1,
      title: "Title 1",
      heading:
        "NextBigBox Ushers in Next-Level Sales Services with the launch of SAAS-based CRM Software",
      subHeading:
        "New Delhi [India], February 13 (ANI/PNN): NextBigBox, one of the leading SAAS-based Fintech of the current milieu, has launched its new CRM software to aid",
      date: { d: "27", m: "Sep" },
      place: "Delhi",
      a: "https://www.business-standard.com/content/press-releases-ani/nextbigbox-ushers-in-next-level-sales-services-with-the-launch-of-saas-based-crm-software-123021300840_1.html",
    },
    {
      image: news2,
      title: "Title 2",
      heading:
        "Meet 10 Visionary Companies Revolutionizing Industries and Inspiring Growth in 2023",
      subHeading: "Nextbigbox has been instrumental in revolutionizing...",
      date: { d: "19", m: "Oct" },
      place: "Delhi",
      a: "https://aninews.in/news/business/business/meet-10-visionary-companies-revolutionizing-industries-and-inspiring-growth-in-202320231019182554/#new_tab",
    },
    {
      image: news3,
      title: "Title 3",
      heading:
        "Next Big Box Provides Leading Digital Marketing & Innovative IT Solutions for Business Growth",
      subHeading: "New Delhi-based leading digital marketing and IT company...",
      date: { d: "19", m: "Fab" },
      place: "Rajasthan",
      a: "https://www.freepressjournal.in/brand-focus/next-big-box-provides-leading-digital-marketing-innovative-it-solutions-for-business-growth#new_tab",
    },
  ];

  const data = [
    {
      image: news4,
      title: "Title 4",
      heading:
        "From Vision to Reality – NextBigBox Shines Spotlight on Year’s Achievements at Annual Conference",
      subHeading:
        "With a theme like “From Vision To Reality,” the event spotlighted NextBigBox’s dedication, an unwavering commitment to bringing digital dreams of its clients into tangible",
      date: { d: "01", m: "Jan" },
      place: "Delhi",
      a: "https://www.hindustantimes.com/brand-stories/from-vision-to-reality-nextbigbox-shines-spotlight-on-years-achievements-at-annual-conference-101696963880000.html#new_tab",
    },
    {
      image: news5,
      title: "Title 5",
      heading:
        "Celebrating 2 Years of Excellence In Digital Marketing & Fintech Services at Nextbigbox  Read more",
      subHeading:
        "Nextbigbox, a prominent player in the IT and marketing sector in India is elated to announce the celebration of its 2-year anniversary this month. Since",
      date: { d: "07", m: "Mar" },
      place: "Delhi",
      a: "https://www.aninews.in/news/business/business/celebrating-2-years-of-excellence-in-digital-marketing-amp-fintech-services-at-nextbigbox20230821171807/",
    },
  ];
  let scrollValue = 2700;
  // window width
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 2700;
  } else {
    scrollValue = 4000;
  }
  return (
    <div>
      <div className="main_news_page_content">
        <section className="news_event_banner">
          <div>
            <h1>News and Events</h1>
            <ul className="mt-2 mb-12">
              <li>
                <b> New Product Launch </b>: Learn about our latest product
                offerings and how they can benefit your business
              </li>
              <li>
                <b> Press Releases </b>: Stay up-to-date on our latest company
                announcements and developments
              </li>
              <li>
                Learn about our latest product offerings and how they can
                benefit your business, Stay up-to-date on our latest company
                announcements and developments, Learn about our latest product
                offerings and how they can benefit your business, Stay
                up-to-date on our latest company announcements and developments
              </li>
            </ul>
            <Link
              className="linkedin-btn flex"
              to={""}
              onClick={() =>
                openInNewTab(
                  "https://www.linkedin.com/company/nextbigbox/mycompany/"
                )
              }
            >
              <p>Check Our LikedIn Page For Latest Updated</p>{" "}
              <img src={blackArrow} alt="img" className="w-12 " />
            </Link>
          </div>
          <img className="p-8" src={news_events} alt="img" />
        </section>
        <section className="news_section1">
          <h2 className="text-center text-[2.2rem] font-proximaNovaExtraBold">
            Latest News & Events
          </h2>
          <div>
            {news.map((item, index) => (
              <div key={index}>
                <NewsCard
                  image={item.image}
                  alt={item.title}
                  heading={item.heading}
                  subHeading={item.subHeading}
                  date={item.date}
                  place={item.place}
                  a={item.a}
                />
              </div>
            ))}
          </div>
          <div>
            {data.map((item, index) => (
              <div key={index}>
                <NewsCard
                  image={item.image}
                  alt={item.title}
                  heading={item.heading}
                  subHeading={item.subHeading}
                  date={item.date}
                  place={item.place}
                  a={item.a}
                />
              </div>
            ))}
          </div>
        </section>
        <CtaComponent
          description={DemoCtaText}
          scrollVal={scrollValue}
          scrollDuration={100}
        />
        <section className="image_gallery">
          <h2 className="text-center text-[2.2rem] font-proximaNovaExtraBold mb-5">
            Event Gallery
          </h2>
          <ImageGallery images={image1} dir={"ltr"} />
          <br></br>
          <br></br>
          <ImageGallery images={image2} dir={"rtl"} />
        </section>
      </div>
      <div className="main_news_page_content main_hrms_page_content_next"></div>

      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent />
      </SectionCard>
    </div>
  );
}

export default NewsAndEventsContent;
