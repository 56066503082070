import React from "react";
import "./LocalSeoContent.css";
import localSeo from "../../media/local-seo-hero.webp";
import localVisibility from "../../media/local search.webp";
import growBusiness from "../../media/business-growth.webp";
import strategicApproach from "../../media/strategic-approac-icon.svg";
import expertGuidance from "../../media/expert-guidance.svg";
import continuousMonitoring from "../../media/continuous-monitoring.svg";
import assuredResult from "../../media/assured-result.svg";
import customerSatisfaction from "../../media/customer-satisfaction.svg";
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import { Link } from "react-router-dom";
import SectionCard from "../CommonComponents/SectionCard";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
import FaqSectionComponent from "../CommonComponents/FaqSectionComponent/FaqSectionComponent";
import { Link as ScrollLink } from "react-scroll";

function WebSeoContent() {
  const faqData = [
    // Define your FAQ data here with questions and answers
    {
      q: "What are Local SEO services?",
      a: "It’s the practising of search engine optimization to enhance the appearance of your business in the local area.  ",
    },
    {
      q: "what’s the importance of Local SEO services?",
      a: "It increases the number of potential customers and expands the business.",
    },
    {
      q: "How can I get Local SEO services?",
      a: "Getting the best SEO agency can be accomplished and robust the customer's reach. ",
    },
    {
      q: "How local SEO services are different from other SEO?",
      a: "Local SEO services emphasize the better enhancement of the number of customers belonging to the closer area.  ",
    },
    {
      q: "What is Google listing in Local SEO? ",
      a: "A brief description i.e., business name, address and contact details is uploaded as a description so that anyone searching for the relevant services can reach you easily.",
    },
  ];

  const DemoCtaText = "Book A Meeting To Start Your Local SEO Journey Today!";
  const faqHeading =
    "You can get the answer of all your question related to the local SEO services for small business. ";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 3050;
  } else {
    scrollValue = 5550;
  }

  return (
    <>
      <div className="local_seo">
        <section className="sp8s1">
          <div className="right">
            <img src={localSeo} alt="img" />
          </div>
          <div className="left">
            <h1>
              What is a <span>Local SEO Service?</span>
            </h1>
            <p>
              It’s the practice of SEO service to enhance your visibility in the
              local area where the customers can find your shop, agency, or
              enterprise easily with the name, location, and contact details.
            </p>
            <p>
              While implementing <b>local SEO service for small businesses,</b>{" "}
              we add the appropriate Name, Address, phone number, location,
              operational time, and all with the description that helps to
              attract and guide customers to locate your place easily with
              complete information. Once your visibility becomes explicit, the
              number of customers automatically gets enhanced and generates more
              revenue for your business.
            </p>
            {/* <button className="p-0">
              <Link
                to={
                  "https://www.nextbigbox.io/blogs/tips-to-improve-the-power-of-search-engine-optimization/"
                }
                target="blank"
              >
                Know More{" "}
              </Link>
            </button> */}
          </div>
        </section>
        <section className="sp8s2">
          <div className="left">
            <h2 className="font-proximaNovaExtraBold text-[1.9rem] leading-[2.1rem] md:text-[2.1rem]">
              Increase your Business Visibility with Local SEO Services
            </h2>
            <p>
              As per the survey report and research, approx. fifty per cent of
              Google searches take place for the local search to find specific
              services which might be shops, gyms, hospitals, schools and many
              more organizations which is directly related to household needs,
              and to enhance your location visibility and tracking, we implement
              the techniques of <b> Local SEO Service </b> where we
              appropriately optimize the address, name, contact details, and
              certain landmark so that the customers can easily find your place.
            </p>
            <p>
              Once you start getting higher visibility, the number of customers
              increases, and your business gets higher revenue.
            </p>
            <ScrollLink
              activeClass="active"
              to="contactComponent"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <button className="border bg-blue-300 border-black rounded mt-4 px-8 py-2 hover:bg-primary">
                Get Quotation
              </button>
            </ScrollLink>
          </div>
          <div className="right">
            <img className=" w-[500px] ml-24" src={localVisibility} alt="img" />
          </div>
        </section>
      </div>
      <CtaComponent
        description={DemoCtaText}
        scrollVal={scrollValue}
        scrollDuration={100}
      />
      <div className="local_seo local_seo_middle">
        <section className="sp8s3">
          <div className="left">
            <img src={growBusiness} alt="img" />
          </div>
          <div className="right">
            <h2 className="font-proximaNovaExtraBold text-[1.9rem] leading-[2.1rem] md:text-[2.1rem] md:leading-[2.1rem]">
              How can our Local SEO services enhance your revenue?
            </h2>
            <p>
              Our<b> Best local seo services</b> can help you generate higher
              revenue in a limited period with its multiple parameters for what
              is implemented, and we work on all of them properly and perfectly
              using all the techniques.
            </p>
            <p>
              <b>Reach : </b> with the perfect location, address, contact
              details, description and all we work to enhance the customer reach
              so that one can easily find your location.
            </p>
            <p>
              <b>Engagement : </b> With the optimized content and implementation
              of your business we initiate the enhancement of engagement so that
              one can easily interact with you and your business.
            </p>
            <p>
              <b>Awareness : </b> Adding brief descriptions and content
              optimization, we always try to create awareness about your brand
              so that it can reach to wider areas.
            </p>
          </div>
        </section>
        <section className="sp8s4">
          <h2 className="font-proximaNovaExtraBold text-center text-[1.9rem] leading-[2.1rem] md:text-[2.1rem]">
            {" "}
            Why Choose NextBigBox to grow your business through local SEO
          </h2>
          <ul>
            <li className="card">
              <img src={strategicApproach} alt="img" />
              <h3 className="text-[1.1rem] subHead">Strategic Approach</h3>
              <p>
                We follow and implement a strategic approach for the fastest and
                most desired result.
              </p>
            </li>
            <li className="card">
              <img src={expertGuidance} alt="img" />
              <h3 className="text-[1.1rem] subHead">Expert Guidance</h3>
              <p>
                We have expert executives with profound experience from their
                prior and existing clients.
              </p>
            </li>
            <li className="card">
              <img src={continuousMonitoring} alt="img" />
              <h3 className="text-[1.1rem] subHead">Regular Monitoring</h3>
              <p>
                We always keep an eye on the services and their result for any
                required implementation.
              </p>
            </li>
            <li className="card">
              <img src={assuredResult} alt="img" />
              <h3 className="text-[1.1rem] subHead">Assured Results</h3>
              <p>
                You can be assured about the result once we get the
                responsibilities of your Local SEO services.
              </p>
            </li>
            <li className="card">
              <img src={customerSatisfaction} alt="img" />
              <h3 className="text-[1.2rem] subHead">
                Highest Customer Satisfaction
              </h3>
              <p>
                Since we implement our ideas in all the possible ways, we have
                higher customer satisfaction.
              </p>
            </li>
          </ul>
        </section>
        <section className="sp1s4 mt-24">
          <FaqSectionComponent faqHeading={faqHeading} faqData={faqData} />
        </section>
      </div>
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <div id="contactComponent">
          <ContactComponent />
        </div>
      </SectionCard>
    </>
  );
}

export default WebSeoContent;
