import React from "react";
import "./EmailMarketingContent.css";
import emailMarketing from "../../media/email_marketing_hero (2).webp";
import emailMarketingServices from "../../media/email-marketing.webp";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
import SectionCard from "../CommonComponents/SectionCard";
import FaqSectionComponent from "../CommonComponents/FaqSectionComponent/FaqSectionComponent";
import { Link as ScrollLink } from "react-scroll";

function EmailMarketingContent() {
  const faqData = [
    {
      q: "What is Email Marketing?",
      a: "Email Marketing is one of the best components of digital marketing where you can find enormous clients and potential customers, especially for your business.",
    },
    {
      q: "How is it different from other digital marketing services?",
      a: "It is a lead generation campaign which is accomplished through email where all the tricks like creative design, content, subject lines are used to engage and attract clients or customers.",
    },
    {
      q: "How can I streamline my business and connectivity with email marketing?",
      a: "It’s simple where you must choose a reliable email marketing agency with all the tools and techniques.",
    },
    {
      q: "What are the prime benefits of email marketing?",
      a: "It helps you enhance the number of customers, and the customers are directly proportional to revenue growth.",
    },
    {
      q: "How much will it cost for effective and transformative services?",
      a: "As per your package, data, numbers of emails, and everything, it will vary. However, we always provide our services at an affordable price.",
    },
  ];
  const faqHeading =
    "Indeed, you might have lots of questions about the loan management system that how it could cultivate your business. Therefore, some essential questions are there which are frequently asked by our esteemed customers and clients.";
  return (
    <div>
      <section className="email_marketing_hero">
        <div className="left">
          <p>EMAIL MARKETING WITH NEXTBIGOX</p>
          <h1>
            Boost your revenue generation process with the leading Email
            Marketing Services
          </h1>
          <h3>
            Let’s start a transformative Email Campaign and reach to potential
            clients.
          </h3>

          <ScrollLink
            activeClass="active"
            to="contactComponent"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <button className="text-[0.9rem] text-center">Ask For Demo</button>
          </ScrollLink>
        </div>
        <div className="right">
          <img className="mt-16" src={emailMarketing} alt="img" />
        </div>
      </section>
      <section className="sp3s2">
        <div className="left">
          <img src={emailMarketingServices} className="p-8" alt="img" />
        </div>
        <div className="right">
          <h2>
            Increase your sale with effective email marketing creating the best
            customer relations
          </h2>
          <p>
            With our email marketing campaign, we care for all those parameters
            and circumstances that help you enhance the strength of the
            customer’s relationship by getting more and more clients. We have an
            expert team that creates the best coordination with their profound
            experience and well-researched marketing strategy.
          </p>
          <ScrollLink
            activeClass="active"
            to="contactComponent"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <button className="text-center text-[0.9rem]">Ask For Demo</button>
          </ScrollLink>
        </div>
      </section>
      <section className="sp3s3">
        <h2>
          Let’s have a glimpse of our strengths and strategy that creates the
          best advertisement
        </h2>
        <div>
          <div className="card">
            <h3 className="font-montserrat font-bold text-[1.2rem] text-center underline">
              1. Email Campaign
            </h3>
            <p>
              Our seasoned specialists will paint intently with you to craft a
              tailor-made email marketing strategy that aligns with your emblem
              identity and commercial enterprise targets. We’ll identify your
              target market, create patron personas, and outline a roadmap for
              successful campaigns.
            </p>
          </div>
          <div className="card">
            <h3 className="font-montserrat font-bold text-[1.2rem] text-center underline">
              2. Creative Design
            </h3>
            <p>
              It’s all about creating a captivating design that can fascinate
              all the viewers or receivers of your email. With perfection in
              analytics and deliverance in quality service, we prominently focus
              on the creativity of design which is the key to success and
              desired growth of your business.
            </p>
          </div>
          <div className="card">
            <h3 className="font-montserrat font-bold text-[1.2rem] text-center underline">
              3. Content Development
            </h3>
            <p>
              With relevant, required, and reliable content that can directly
              impress the viewers, we create captivating and relevant content
              which are focused on better revenue generation. Our strategy with
              the reliable content creation always helps you attract enormous
              customers.
            </p>
          </div>
          <div className="card">
            <h3 className="font-montserrat font-bold text-[1.2rem] text-center underline">
              4. List Management
            </h3>
            <p>
              A perfect and explicit listing of email marketing is always
              beneficial for a better record and to identify existing and
              upcoming customers. With the perfect listing, you will be able to
              set the eyes on all those customers and it will help you have a
              sustainable follow-up.
            </p>
          </div>
          <div className="card">
            <h3 className="font-montserrat font-bold text-[1.2rem] text-center underline">
              5. Personalisation and Automation
            </h3>
            <p>
              With this strategy, we provide the best delivery of email, which
              is created with the requirements and applicability. With
              personalization and automation, we provide quick and relevant
              message deliveries and replies as well that help you establish
              connectivity.
            </p>
          </div>
          <div className="card">
            <h3 className="font-montserrat font-bold text-[1.2rem] text-center underline">
              6. A/B Testing
            </h3>
            <p>
              With the optimization and regular testing of emails and their
              performance we always keep an eye on the performance and with the
              requirements, we work for better and suitable subject lines,
              content, and templates as well so that the campaign can have
              better performance and efficiency.
            </p>
          </div>
          <div className="card">
            <h3 className="font-montserrat font-bold text-[1.2rem] text-center underline">
              7. Analytics and Reporting
            </h3>
            <p>
              It’s our priority and the secret of successful email marketing
              that we have an analytics team that can help you be updated with
              the explicit report of the outcome and performance, and it imparts
              us insight for the betterment of our services and best
              performance.
            </p>
          </div>
          <div className="card">
            <h3 className="font-montserrat font-bold text-[1.2rem] text-center underline">
              8. Deliverability and Compliance
            </h3>
            <p>
              With a focus on deliverability and compliance, we always ensure
              that the composed emails are properly delivered to the inbox of
              the receiver, not in spam. To perform these actions, we profoundly
              work on reputation management to establish reliability and trust.
            </p>
          </div>
          <div className="card">
            <h3 className="font-montserrat font-bold text-[1.2rem] text-center underline">
              9. Support and Consultation
            </h3>
            <p>
              With our all-time support and consultation, we are always there
              for better service delivery and for that, our team of experts is
              always available for technical support where we try to resolve all
              the issues immediately. We never allow any interruption to come in
              between your services.
            </p>
          </div>
        </div>
      </section>

      <div className="main_api_page_content main_api_page_content_next">
        <section className="sp2s4 mt-24">
          <FaqSectionComponent faqHeading={faqHeading} faqData={faqData} />
        </section>
      </div>
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        id={"contactComponent"}
      >
        <div id="contactComponent">
          <ContactComponent />
        </div>
      </SectionCard>
    </div>
  );
}

export default EmailMarketingContent;
