import React from "react";
import "./WebSeoContent.css";
import WebSeoHero from "../../media/web_seo_hero.webp";
import web_seo_icon from "../../media/web-seo-icon.png";
import local_seo_icon from "../../media/local-seo-icon.png";
import enterprise_seo_icon from "../../media/enterprise-seo-icon.png";
import ecommerce_seo_icon from "../../media/ecommerce-seo-icon.png";
import check from "../../media/check.png";
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import { Link } from "react-router-dom";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
import SectionCard from "../CommonComponents/SectionCard";
import FaqSectionComponent from "../CommonComponents/FaqSectionComponent/FaqSectionComponent";
import PricingSectionContent from "../CommonComponents/PricingSection/PricingSectionContent";
import technical_seo_icon from "../../media/technical_seo_icon.webp";
import keywort_research_icon from "../../media/keyword_research_icon.webp";
import onPage_offPage_seo_icon from "../../media/onpage_offpage_seo_icon.webp";
import seo_anaylytics from "../../media/seo_analytics_icon.webp";

function WebSeoContent() {
  const faqData = [
    // Define your FAQ data here with questions and answers
    {
      q: "What are Web SEO Services?",
      a: "It’s an essential part of digital marketing which is used to enhance the ranking of websites for the success of business with the customers reach.",
    },
    {
      q: "What are the benefits of SEO services in Digital Marketing? ",
      a: "It enriches the number of visitors and website traffic which is directly proportional to the success of the business. ",
    },
    {
      q: "How much time will it take to get the desired result? ",
      a: "Although it takes three months to get the results, the appearance of keywords starts from the first month only.",
    },
    {
      q: "How to choose affordable services for small businesses?",
      a: "Getting SEO results can take between some weeks to months based on the competition, the website’s present ranking, and the efforts it requires.",
    },
  ];
  const faqHeading = "";

  const DemoCtaText =
    "Want to Dominate Search Engine Rankings? Propel Your Business to the Top with Our SEO Services.";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 4550;
  } else {
    scrollValue = 10950;
  }
  const ScrollDown = (scrollVal, scrollDuration) => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 500) {
      let val = scrollVal - window.scrollY;
      setTimeout(() => {
        window.scrollBy({ top: val, letf: 0, behavior: "smooth" });
        // console.log('val',val);
      }, scrollDuration);
    } else {
      let val = scrollVal - window.scrollY;
      setTimeout(() => {
        window.scrollBy({ top: val, letf: 0, behavior: "smooth" });
        // console.log(val);
      }, scrollDuration);
    }
  };
  return (
    <>
      <div className="web_seo_main">
        <section className="sp7s1">
          <div className="left">
            <h1>
              Best SEO Company in India with Expert SEO Team for Top Rankings
            </h1>
            <p>
              If you have an online business with a registered website and all,
              you would like its appearance on the first ranks on the search
              engine result page so that the users can find you easily with the
              keywords. Our <b>Best SEO Services Agencies</b> are there to
              accomplish your desire with all its components like web SEO, Local
              SEO, Enterprise SEO, E-Commerce, Online Reputation Management,
              etc.,
            </p>
            <p>
              We have a team of experts to accomplish all the tasks related to
              SEO services like keyword research, backlinking, description, meta
              tag optimization and all so that the ranking and visibility of
              your keywords can be ensured easily within the desired period.
            </p>
            <ul>
              <li>ON Page SEO</li>
              <li>OFF Page SEO</li>
            </ul>
            <Link
              to={"#"}
              onClick={() => {
                ScrollDown(scrollValue, 100);
              }}
              className="q_button"
            >
              Get Quotation
            </Link>
          </div>
          <div className="right">
            <img className="p-3" src={WebSeoHero} alt="web_seo_hero" />
          </div>
        </section>
        <section className="sp4s2">
          <h2 className="text-center mt-24 text-[2.1rem] leading-[2.2rem] font-proximaNovaExtraBold">
            What is SEO Services And We Offers?
          </h2>
          <div>
            <div className="card" data-aos="zoom-in">
              <img src={web_seo_icon} alt="img" className=" w-8 h-8" />
              <h3 className="font-bold text-[1.2rem]">Web SEO Services</h3>
              <p>
                SEO is a technique that is used to enhance the appearance of the
                website on the higher or first rank of the search engine result
                page. To perform the best SEO services our experts appropriately
                implement all the tasks <b> On-Page SEO, Off-Page SEO,</b>{" "}
                Article submission and all and therefore we can ensure that we
                will be your primary choice for the Best SEO company in India at
                affordable prices as well.
              </p>
            </div>
            <div className="card" data-aos="zoom-in">
              <img src={local_seo_icon} alt="img" className=" w-8 h-8" />
              <h3 className="font-bold text-[1.2rem]">Local SEO Services </h3>
              <p>
                It might be the biggest possibility that your outlet or shop
                might be there in a prominent place. Even after having a big
                shop, you might not have gotten the expected number of
                customers. Our <b>local SEO service</b> is there to resolve your
                issue and attract more customers with proper Local Search Engine
                Optimization with the relevant strategy and techniques.
              </p>
            </div>
            <div className="card" data-aos="zoom-in">
              <img src={enterprise_seo_icon} alt="img" className=" w-8 h-8" />
              <h3 className="font-bold text-[1.2rem]">
                Enterprise SEO Services{" "}
              </h3>
              <p>
                Once your services are visible and appear with multiple
                products, it automatically becomes easy for customers to get
                facilitated from your side smoothly and it also enhances the
                reach of customers for your store. Enterprise SEO can enhance
                the <b> appearance </b> of your business including all the
                enlisted products.
              </p>
            </div>
            <div className="card" data-aos="zoom-in">
              <img src={ecommerce_seo_icon} alt="img" className=" w-8 h-8" />
              <h3 className="font-bold text-[1.2rem]">
                E-commerce SEO Services{" "}
              </h3>
              <p>
                Our E-commerce SEO service is there to enhance your website
                <b> visibility</b> along with all the products you are providing
                and therefore we are there to bridge the gap between you and
                your potential customers. With all techniques applied for
                E-commerce SEO like product listing, description, keyword
                stuffing and all, we provide quality service within the
                committed period at an affordable price.
              </p>
            </div>
          </div>
        </section>
        <section className="sp7s3">
          <div className="left mt-8">
            {/* h2 */}
            <h2>Our Best SEO services elevate your website ranking. </h2>
            <p>
              The specific SEO services that make us the{" "}
              <b> best SEO company in India</b> are associated with all the
              techniques that are implemented by experts having profound
              experience and expertise appear in the ensured result i.e., in the
              desired ranking of your website or your products. With in-depth
              analysis, audit and feedback our experts always keep an eye on the
              growth of the ranking.
            </p>
            <p>
              We follow all the rules and guidelines and implement all the
              required actions like On Page, Off page, meta description, meta
              tag optimization, and keyword analysis etc., Apart from these, we
              appropriately focus on the content creation from our experts which
              is the key to success of the SEO service and therefore, with a
              brief analysis we accomplish the SEO services with all its
              requirements. overall, we have included every technique for the
              best possible results.
            </p>
          </div>
          <div className="right">
            <ol>
              <li>
                <img src={check} alt="img" /> Improved visibility in search
                engine result pages
              </li>
              <li>
                <img src={check} alt="img" /> Drive organic traffic to your
                website
              </li>
              <li>
                {" "}
                <img src={check} alt="img" /> Produce more sales leads
              </li>
              <li>
                {" "}
                <img src={check} alt="img" /> Better keyword ranking
              </li>
              <li>
                {" "}
                <img src={check} alt="img" /> Increased website conversions
              </li>
              <li>
                {" "}
                <img src={check} alt="img" /> Boosted user experience
              </li>
              <li>
                {" "}
                <img src={check} alt="img" /> Increased website authority
              </li>
              <li>
                {" "}
                <img src={check} alt="img" /> Generate brand awareness
              </li>
              <li>
                {" "}
                <img src={check} alt="img" /> Manage and optimize page speed
              </li>
              <li>
                {" "}
                <img src={check} alt="img" /> Monitor and enhance the backlink
                profile
              </li>
            </ol>
          </div>
        </section>
        <section className="api_section3 api_section3_r">
          <div className="left w-['80%']">
            {/* h2 */}
            <h2 className="text-center md:text-start font-proximaNovaExtraBold leading-[2.2rem] text-[2.2rem] mb-3">
              Top Results with Our Expert SEO Services
            </h2>
            <p>
              We use all the technical aspects of{" "}
              <b>Best SEO service Agnecies</b> for better results i.e.,
              cultivate the appearance of your website on the search engine
              results page and ensure that we use technical SEO audit, Keyword
              research, On-Page, Off-page SEO, analytics and tracking of the
              results. With all these techniques we always try to perform with
              ensured success.
            </p>
            <ul className="w-[90%]">
              <li>
                {" "}
                <b> Technical SEO Audit : </b> Analysis of the website’s
                infrastructure, indexing, enhancement of performance and
                visibility on the search engine.
              </li>
            </ul>
            <ul className=" w-[90%]">
              <li>
                {" "}
                <b> Keyword Research : </b> Appropriate and relevant keywords
                research with their search volume and density as well.
              </li>
            </ul>
            <ul className=" w-[90%]">
              <li>
                {" "}
                <b> On-Page/ Off-Page SEO : </b> Optimize the website content
                with the relevant keywords and enhance the trustworthiness of
                the website through external linking.
              </li>
            </ul>
            <ul className=" w-[90%]">
              <li>
                {" "}
                <b> Analytics and Tracking : </b> Tracking the result,
                requirement of implementation and planning as per the vision,
                and getting insight.
              </li>
            </ul>
          </div>
          <div className="right right_4">
            <div className="sub_left" data-aos="zoom-out-right">
              <img src={technical_seo_icon} alt="img" />
              <h3 className="mt-6">Technical SEO Audit</h3>
              <p>
                It’s the essential practice of SEO services to get insight from
                the actions and effects and it helps.
              </p>
            </div>
            <div className="sub_right" data-aos="zoom-in-right">
              <img src={keywort_research_icon} alt="img" />
              <h3 className="mt-6">Keyword Research</h3>
              <p>
                Everything, especially for SEO services resides and starts from
                keyword research and it’s our prior goal.
              </p>
            </div>
            <div className="sub_left" data-aos="zoom-out-right">
              <img src={onPage_offPage_seo_icon} alt="img" />
              <h3 className="mt-6">On and Off-page SEO</h3>
              <p>
                With the appropriate content optimization services and on-page
                SEO with blogs, we accomplish this task properly.
              </p>
            </div>
            <div className="sub_right" data-aos="zoom-out-right">
              <img src={seo_anaylytics} alt="img" />
              <h3 className="mt-6">Analytics and Tracking</h3>
              <p>
                We track and analyze all the results and all the results and
                finally get insight from the results and requirements as well.
              </p>
            </div>
          </div>
        </section>

        <CtaComponent
          description={DemoCtaText}
          scrollVal={scrollValue}
          scrollDuration={100}
        />
        <section className="pricing-container">
          {/* h3 */}
          <h3 className="text-center font-proximaNovaExtraBold text-[2.2rem] leading-[2.1rem] my-12">
            Get the Best SEO pricing packages for Your Business
          </h3>
          <PricingSectionContent />
        </section>

        <section className="sp1s4 mt-24">
          <FaqSectionComponent faqHeading={faqHeading} faqData={faqData} />
        </section>
      </div>
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent />
      </SectionCard>
    </>
  );
}

export default WebSeoContent;
