import React from "react";
import "./GoogleAdsPageContent.css";
import GoogleAds from "../../media/google_ads_banner.webp";
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
import SectionCard from "../CommonComponents/SectionCard";
import clear_pricing from "../../media/clear-pricing.webp";
import quick_reporting from "../../media/quick-reporting.webp";
import proactive_campaign_management from "../../media/proactive_campaign_management.webp";
import conversion_tracking from "../../media/conversion_tracking.webp";
import proactive_campagin_management from "../../media/proactive_campagin_management.jpeg";
import google_custom_ads from "../../media/google_custom_ads.jpeg";
import FaqSectionComponent from "../CommonComponents/FaqSectionComponent/FaqSectionComponent";

function GoogleAdsPageContents() {
  const faqData = [
    // Define your FAQ data here with questions and answers
    {
      q: "What is Google Ads Service?",
      a: "It’s an initiative and specific service that is used to enhance the visibility and appearance of the products.",
    },
    {
      q: "How can it help to boost the business?",
      a: "You can enhance your visibility and get a broad area of customers and once your business flourishes it will get higher revenue generation.  ",
    },
    {
      q: "What are the features and benefits of the Google Ads services?",
      a: "It has multiple features and benefits like quick results efficient marketing results, monitoring, and all. ",
    },
    {
      q: "How many days will it take for the better leads and customers?",
      a: "It becomes effective just after the campaign setup, however considering the quality of leads it can be said perfectly.",
    },
    {
      q: "What are the prices for the Google Ads services?",
      a: "It will vary on the range of campaigns and all for what it is used.",
    },
  ];
  const DemoCtaText =
    "We transform your dream of streamlining your business online into reality. Let’s dive into the world of IT and digital marketing solutions with our expertise.";
  const faqHeading =
    "Indeed, you might have lots of questions about the loan management system that how it could cultivate your business. Therefore, some essential questions are there which are frequently asked by our esteemed customers and clients.";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 3520;
  } else {
    scrollValue = 7600;
  }

  return (
    <>
      <div className="services_content_page">
        <section className="sp1s1 google_ads" data-aos="fade-up">
          <div className="left">
            <h1>
              How Google Ads Compaign Accelerate <span>Your Business</span>
            </h1>
            <div>
              <p>
                Get elevated revenue with our efficient Google Ads services
                which can enhance the appearance of your brand in front of the
                audience and customers it is also capable of imparting enormous
                services like targeted customer reach, enhanced visibility,
                measurable results, control & flexibility, brand building, local
                marketing, competitor insights, automated solutions, increased
                ROI and all. Therefore, let’s try our transformative Google Ads
                services that can be beneficial for your business and revenue
                generation.
              </p>
              <p>
                With a single click on your Google Ads campaign, you can find
                potential customers and higher website traffic for the further
                enhancement of your business and customer reach as well. Our
                experts help you with complete insight and provide the ensured
                outcomes and success of your business.
              </p>
              <ul className="mt-4 flex gap-[1rem]">
                <li>Targeted Advertising</li>
                <li>Cost Control</li>
                <li>Performance Tracking</li>
                <li>Ad Formats</li>
              </ul>
            </div>
          </div>
          <div className="right">
            <img src={GoogleAds} className="" alt="img" />
          </div>
        </section>
        <section className="sp1s2 mt-4">
          <div className="left mt-32">
            <div className="">
              <h1>The diverse range of Google Ads services that we offer</h1>
              <p data-aos="fade-up">
                Our Google Ads services are there to enhance your appearance on
                priority with certain keywords which is enough to cultivate and
                enrich the brand awareness. It helps you enhance your revenue
                with higher customer engagement, which is the key to success via
                digital marketing services. We have different Google ads
                services for different reasons.
              </p>
              <ul>
                <li data-aos="fade-up">
                  <b>Search Advertising :</b> Advertising which is the most
                  effective and commonly known service used to grasp the
                  attention of customers helps to enhance the efficiency of the
                  customers.
                </li>
                <li data-aos="fade-up">
                  <b>Display Advertising :</b> Our display advertising allows
                  your brand to appear explicitly in a broader area of the
                  audience. We also deploy and set the ad campaign as per the
                  requirements and trends going on.
                </li>
                <li data-aos="fade-up">
                  <b>Video Ads :</b> Since it’s the most effective and
                  transformative feature of marketing agencies and therefore, we
                  always care for a better presentation for the marketing
                  service through Video Ads.
                </li>
                <li data-aos="fade-up">
                  <b>Google Shopping Ads </b>: Considering the huge requirements
                  for e-commerce stores, we carefully create the product listing
                  and optimize their name, quality, and all and therefore, it is
                  our strength and prominent services.
                </li>
              </ul>
            </div>
          </div>
          <div className="right mt-12">
            <ul>
              <li data-aos="fade-up">
                <b>Remarketing :</b> It helps you recall and revisit your
                previous visitors all those who were coming to your website and
                already had engaged with your services. And therefore, with our
                remarketing services, it becomes easy to attract your customers.
              </li>
              <li data-aos="fade-up">
                <b>Keyword Management :</b> We always implement our Google ads
                services with the appropriate keyword research, and it is enough
                to enhance the appearance of your products and services. It
                helps your products be on the top always.
              </li>
              <li data-aos="fade-up">
                <b>Campaign And Ad Copy Creation :</b> With our Ad copy creation
                and Google Ad campaign, we create captivating ads that help to
                grasp the attention of the customer at first sight.
              </li>
              <li data-aos="fade-up">
                <b>Performance Marketing :</b> It’s the central and specific
                service that is performed from our side and it helps the clients
                to enhance the customer experience by making their search easy
                and convenient.
              </li>
              <li data-aos="fade-up">
                <b>Mobile Advertising Strategy :</b> Maximum numbers of the
                audience are using smartphones while proceeding with any online
                services and therefore, we focus on Mobile Advertising
                strategies for better performance. .
              </li>
              <li data-aos="fade-up">
                <b>Ad Performance Analytics and Reporting: </b> With a brief
                analysis and reporting we always keep eyes on the perfection and
                reporting. We maintain in-depth reporting and analytics on
                previous and ongoing projects so that we can ensure the best and
                desired results for you.
              </li>
            </ul>
          </div>
        </section>
      </div>
      <CtaComponent
        description={DemoCtaText}
        scrollVal={scrollValue}
        scrollDuration={100}
      />
      <div className="services_content_page google_ads_services services_content_page_middle">
        <section className="sp1s3">
          <h2
            className="text-center text-[2.2rem] leading-[2.2rem] font-proximaNovaExtraBold mb-4"
            data-aos="fade-up"
          >
            Reasons to Get NextBigBox Google Ads Service
          </h2>
          <p data-aos="fade-up">
            Choosing NextBigBox as your ultimate Google Ads service provider can
            provide you excellent competitive advantages and many more benefits,
            including –
          </p>
          <ul>
            <li data-aos="fade-right">
              <b> Proactive Campaign Management</b>
              <img
                src={proactive_campagin_management}
                className="cardImg"
                alt="img"
              />
            </li>
            <li data-aos="fade-up">
              <b>Custom Google Ads Strategy</b>
              <img src={google_custom_ads} className="cardImg" alt="img" />
            </li>
            <li data-aos="fade-left">
              <b> Clear Pricing</b>
              <img src={clear_pricing} className="cardImg" alt="img" />
            </li>
            <li data-aos="fade-right">
              <b>Quick Reporting</b>
              <img src={quick_reporting} className="cardImg p-3" alt="img" />
            </li>
            <li data-aos="fade-up">
              <b>Conversion Tracking</b>
              <img src={conversion_tracking} className="cardImg" alt="img" />
            </li>
            <li data-aos="fade-left">
              <b>Performance Monitoring</b>
              <img
                src={proactive_campaign_management}
                className="cardImg"
                alt="img"
              />
            </li>
          </ul>
        </section>
        <section className="sp1s4 mt-24">
          {/* <div className="faq-container">
            <h2>Frequently Asked Questions</h2>
            {faqData.map((item, index) => (
              <div key={index} className="faq-item " data-aos="fade-up">
                <button
                  className={`accordion flex justify-between ${
                    activeAccordion === index ? "active" : " "
                  }`}
                  onClick={() => toggleAccordion(index)}
                >
                  <span>{item.question}</span>
                  <span>
                    {activeAccordion === index ? (
                      <span className="float-right">&#8595;</span>
                    ) : (
                      <span> &uarr; </span>
                    )}
                  </span>
                </button>
                <div
                  className={`panel ${
                    activeAccordion === index ? "active visible" : ""
                  }`}
                >
                  {item.answer}
                </div>
              </div>
            ))}
          </div> */}
          <FaqSectionComponent faqHeading={faqHeading} faqData={faqData} />
        </section>
      </div>
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent />
      </SectionCard>
    </>
  );
}

export default GoogleAdsPageContents;
