import React from "react";
import "./FullWidthCard.css";
function FullWidthCard({ heading, subheading, img }) {
  return (
    <section className="full_card">
      <div className="upper">
        <h2 className="e2e_lead text-center font-bold text-[2.2rem] leading-[1.9rem] font-proximaNovaExtraBold mb-4">
          {heading}
        </h2>
        <p className="text-center mx-4 w-100 font-montserrat">{subheading}</p>
      </div>
      <img data-aos="zoom-in" src={img} alt="img" className="e2elms" />{" "}
    </section>
  );
}

export default FullWidthCard;
