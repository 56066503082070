import React from "react";
import "./SalesCrmPageContent.css";
import { Link } from "react-router-dom";
import Cards from "../CommonComponents/Cards/Cards";

import FullWidthCard from "../CommonComponents/Cards/FullWidthCard";
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import email_feature from "../../media/f01.png";
import lead_feature from "../../media/f02.png";
import varification_feature from "../../media/f03.png";
import tracking_feature from "../../media/f04.png";
import sales_workflow_feature from "../../media/f05.png";
import notification_feature from "../../media/f06.png";
import dynamic_form_feature from "../../media/f07.png";
import sales_regimentation_feature from "../../media/f08.png";
import workday_planning_feature from "../../media/f09.png";
import Report_feature from "../../media/f10.png";
import apis_feature from "../../media/f11.png";
import e2e_fintech_crm from "../../media/e2e_fintech_crm.webp";
import lead_management from "../../media/lead_management.webp";
import blue_arrow from "../../media/blue_arrow.jpg";
import sales_reporting from "../../media/sales_reporting.webp";
import role_based_authentication from "../../media/role_based_authentication.webp";
import SectionCard from "../CommonComponents/SectionCard";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
import salesFollowUp from "../../media/follow_up.webp";

function SalesCrmPageContent() {
  const crm_featured_item = [
    {
      heading: "Email, WhatsApp, SMS",
      icon: email_feature,
      desc: "Create bulk campaigns, send automated messages, and track easily.",
    },
    {
      heading: "Lead scoring",
      icon: lead_feature,
      desc: "Get the analytics and scoring of leads as per the efficiency quality.",
    },
    {
      heading: "Online Verification",
      icon: varification_feature,
      desc: "Get the discriminating capacity for the verification of leads.",
    },
    {
      heading: "Tracking",
      icon: tracking_feature,
      desc: "Keep an eye on every lead and response from the teammates.",
    },
    {
      heading: "Sales workflows",
      icon: sales_workflow_feature,
      desc: "Enhance the productivity and workflows of the sales with ease. ",
    },
    {
      heading: "Notifications",
      icon: notification_feature,
      desc: "Keeps you alert with reminders and notifications for scheduled tasks.",
    },
    {
      heading: "Role-based permissions",
      icon: varification_feature,
      desc: "Easy to fix the role-based accessibility with different roles and positions.",
    },
    {
      heading: "Dynamic forms",
      icon: dynamic_form_feature,
      desc: "Dynamic and multilayer forms efficiently work with updated information.",
    },
    {
      heading: "Sales regimentation",
      icon: sales_regimentation_feature,
      desc: "Works with the sales regimentation as per the customizations.",
    },
    {
      heading: "Workday planning",
      icon: workday_planning_feature,
      desc: "All the assignments, meetings and scheduled work can be saved easily. ",
    },
    {
      heading: "Reports",
      icon: Report_feature,
      desc: "Precisely measure the repost and keep track of the working efficiency. ",
    },
    {
      heading: "APIs",
      icon: apis_feature,
      desc: "Integrate as per your need to ensure a robust API and developer platform.",
    },
  ];
  const DemoCtaText =
    "We transform your dream of streamlining your business online into reality. Let’s dive into the world of IT and digital marketing solutions with our expertise.";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 4320;
  } else {
    scrollValue = 8050;
  }

  return (
    <div className="salescrm_page_content_container">
      <div className="salescrm_page_content">
        <section className="salescrm_page_content_hero px-0 md:px-8 pt-[0rem] md:pt-[7rem]">
          <div className="upper">
            <h1 data-aos="zoom-in" className="mt-4">
              Efficiently Accelerate your Sales with Sales CRM Software
            </h1>
            <p className="mt-3 text-lg font-semibold">
              Manage Multiple projects with one software like teams.
            </p>
          </div>
          <div className="bottom mt-14">
            <div className="left" data-aos="zoom-in-up">
              <img src={lead_management} alt="img" />
            </div>
            <div className="right" data-aos="zoom-in-down">
              <h2 className="text-[2.1rem] font-proximaNovaExtraBold mb-2">
                Lead Management
              </h2>
              <p>
                Integrate sales communications with Chat, SMS, Email, WhatsApp,
                and Phone.
              </p>
              <ul className="list-item">
                <li>
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                  </svg>
                  <span>Click-to-call and record conversation.</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                  </svg>
                  <span>Create and save message templates.</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                  </svg>
                  <span>Set-up multi-channel auto responders.</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                  </svg>
                  <span>Create and save message templates.</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                  </svg>
                  <span>Create and save message templates.</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                  </svg>
                  <span>Create and save message templates.</span>
                </li>
              </ul>
              <Link
                className="reading_page_link"
                to={"https://www.nextbigbox.io/blogs/"}
                target="blank"
              >
                What is Sales CRM. Explore <img src={blue_arrow} alt="img" />
              </Link>
            </div>
          </div>
        </section>

        <section className="role_based_authentication px-2 md:px-8">
          <h2 className=" text-center mt-20 text-[2.2rem] font-proximaNovaExtraBold">
            Role Based Authentication
          </h2>
          <div>
            <div className="left" data-aos="zoom-in-down">
              <p>Security : Our First Priority</p>
              <h3 className="pt-2 pr-36">
                Track and generate reports as per your responsibility as a sales
                executive which is completely secure and transparent.
              </h3>
              <ul className="p-4">
                <li>
                  <span>
                    <svg
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      className="bi bi-shield-lock"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />{" "}
                      <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />{" "}
                    </svg>
                    <p className="role_head">Admin Access</p>
                  </span>
                  <p>
                    It helps you keep your eyes on each message and record
                    through WhatsApp, Email, SMS, and your team’s activity as
                    well.
                  </p>
                </li>
                <li>
                  <span>
                    <svg
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      className="bi bi-shield-lock"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />{" "}
                      <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />{" "}
                    </svg>
                    <p className="role_head">Account Team</p>
                  </span>
                  <p>
                    Keep the complete details of the entire team and keep an eye
                    on what is going on.
                  </p>
                </li>
                <li>
                  <span>
                    <svg
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      className="bi bi-shield-lock"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />{" "}
                      <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />{" "}
                    </svg>
                    <p className="role_head">Sales Executive</p>
                  </span>
                  <p>
                    Helps in receiving the leads and connecting the lead easily
                    with the call, SMS and different communication tools.
                  </p>
                </li>
                <li>
                  <span>
                    <svg
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      className="bi bi-shield-lock"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />{" "}
                      <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />{" "}
                    </svg>
                    <p className="role_head">Project Manager</p>
                  </span>
                  <p>
                    Accumulate and save all the records performed by the team
                    that help to discriminate and analyse the work and
                    productivity.
                  </p>
                </li>
              </ul>
            </div>
            <div className="">
              <img
                className="pt-6"
                src={role_based_authentication}
                alt="img"
                data-aos="zoom-in-up"
              />
            </div>
          </div>
        </section>
      </div>
      <CtaComponent
        description={DemoCtaText}
        scrollVal={scrollValue}
        scrollDuration={100}
      />
      <div className=" salescrm_page_content_middle pt-4 md:px-4 md:pt-8">
        <FullWidthCard
          heading={" End-to-End Sales Management "}
          subheading={
            "Provide a seamless digital experience to your teams with loan application workflows"
          }
          img={e2e_fintech_crm}
          data-aos="zoom-in-up"
        />
        <section className="crm_reporting_analysis">
          <div className="right" data-aos="zoom-in-up">
            <img src={sales_reporting} className="sales-reporting" alt="img" />
          </div>
          <div className="left software-integration" data-aos="zoom-in-down">
            <h2>Reporting and Analysis</h2>
            <p className="px-6 md:px-0">
              Integrate your service with automation and enhance the speed of
              product delivery, response and connectivity with your customers
              where the number of products and services doesn't matter because
              you can simultaneously perform every action on a single platform.
            </p>
            <ul className="my-feature px-8 md:px-0">
              <ul>
                <li>Sales Performance Metrics</li>
                <li>Pipeline and Opportunity Metrics</li>
                <li>Customer Engagement and Satisfaction</li>
              </ul>
              <ul>
                <li>Sales Team Performance</li>
                <li>Market and Competitor Analysis</li>
                <li>Forecast and Predictive Analytics</li>
              </ul>
            </ul>

            <Link
              to={"https://www.nextbigbox.io/blogs/"}
              className="reading_page_link pl-8"
              target="blank"
            >
              What is Sales CRM. Explore <img src={blue_arrow} alt="img" />
            </Link>
          </div>
        </section>
        <section className="crm_featured need_not_padding">
          <h1 className="f_head">A Featured Powered Fintech CRM</h1>
          <div className="crm_featured_item" data-aos="zoom">
            {crm_featured_item.map((item, index) => (
              <Cards
                key={index}
                heading={item.heading}
                icon={item.icon}
                desc={item.desc}
                className={{ display: "flex", justifyContent: "left" }}
              />
            ))}
          </div>
        </section>
        <section
          className="salescrm_page_content_hero  "
          data-aos="zoom-in-down"
        >
          <div className="upper"></div>
          <div className="bottom">
            <div className="left md:ml-12" data-aos="zoom-in-left">
              <img src={salesFollowUp} alt="img" />
            </div>
            <div
              className="right mx-4 md:mx-0 overflow-hidden"
              data-aos="zoom-in-right"
            >
              <h2 className="font-proximaNovaExtraBold text-[2rem]">
                Follow UP
              </h2>
              <p>
                Enable contextual sales communications over Email, Chat,
                WhatsApp, SMS, and Phone.
              </p>
              <ul className="list-item">
                <li>
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                  </svg>
                  <span>Content Creation.</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                  </svg>
                  <span>Save this template within the chat platform.</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                  </svg>
                  <span>Save this template within your SMS.</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                  </svg>
                  <span>Send or deliver the message.</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                  </svg>
                  <span>Create and save message templates.</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />{" "}
                  </svg>
                  <span>Create and save message templates.</span>
                </li>
              </ul>
              <Link
                className="reading_page_link pl-2"
                to={"https://www.nextbigbox.io/blogs/"}
                target="blank"
              >
                What is Sales CRM. Explore <img src={blue_arrow} alt="img" />
              </Link>
            </div>
          </div>
        </section>
      </div>
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent />
      </SectionCard>
    </div>
  );
}

export default SalesCrmPageContent;
