import React from "react";
import s1i1 from "../../../media/s1i1.png";
import s1i2 from "../../../media/s1i2.jpg";
import s1i3 from "../../../media/s1i3.png";
import s2i1 from "../../../media/s2i1.jpg";
import s2i2 from "../../../media/s2i2.jpg";
import s3i1 from "../../../media/s3i1.png";
import s3i2 from "../../../media/s3i2.png";
import s3i3 from "../../../media/s3i3.png";

// import arrowBlack from "../../../media/arrow-black.svg";
import arrowBlack from "../../../media/arrow-icon.svg";
import { Link } from "react-router-dom";

function CaseStudies() {
  return (
    <div className="lg:pt-20 lg:px-32">
      <div>
        <h2 className="text-[2.2rem] text-center font-proximaNovaExtraBold font-semibold mt-1">
          Case Studies
        </h2>
        <p className="text-center text-xl font-montserrat">
          Navigating Through Real-World Solutions
        </p>
      </div>
      {/* Grid  */}
      <div className="mt-2 px-8 lg:px-0 flex flex-col lg:flex-row justify-center gap-4 lg:gap-12">
        <div className="lg:w-[24%] flex flex-col gap-4">
          <div className="flex flex-col gap-8 lg:gap-2 rounded-3xl border bg-[#ddd] my-4 pb-6">
            <img src={s1i1} className="rounded-3xl" alt="case-studies" />
            <div className="p-2 my-2">
              <p className="text-[1.2rem] font-semibold font-proximaNovaExtraBold">
                Fintech CRM
              </p>
              <p className="text-[.9rem] font-montserrat">
                We create and customize fintech CRM for your business and better
                client relationship management which is easy to use for the
                entire team.
              </p>
              <button className="mt-2 rounded-[100%] bg-white p-1 group hover:bg-transparent border-2 border-[transparent] hover:border-2 hover:border-white shadow-md">
                <Link to="/case-studies" target="blank">
                  <img
                    src={arrowBlack}
                    className="w-4 h-4 group-hover:translate-x-1 transition-all duration-400"
                    alt="arrow_icon group-hover:bg-none"
                  />
                </Link>
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2 rounded-3xl border bg-[#ddd] my-4 pb-10">
            <img src={s1i2} className="rounded-3xl" alt="case-studies" />
            <div className="p-2 my-2">
              <p className="text-[1.2rem] font-semibold  font-proximaNovaExtraBold">
                Ecommerce Website
              </p>
              <p className="text-[.9rem] font-montserrat">
                With the precise design and appropriate layout, our E-commerce
                website design and SEO services help you be ahead of the
                competitors.
              </p>
              <button className="mt-2 rounded-[100%] bg-white p-1 group hover:bg-transparent border-2 border-[transparent] hover:border-2 hover:border-white shadow-md">
                <Link to="/case-studies">
                  <img
                    src={arrowBlack}
                    className="w-4 h-4 group-hover:translate-x-1 transition-all duration-400"
                    alt="arrow_icon group-hover:bg-none"
                  />
                </Link>
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2 rounded-3xl border bg-[#ddd] my-4">
            <img src={s1i3} className="rounded-3xl" alt="case-studies" />
            <div className="p-2 my-2">
              <p className="text-[1.2rem] font-semibold  font-proximaNovaExtraBold">
                Fintech App{" "}
              </p>
              <p className="text-[.9rem] font-montserrat">
                We work for fintech Apps and design them with customized
                features so that all the services can be covered easily.
              </p>
              <button className="mt-2 rounded-[100%] bg-white p-1 group hover:bg-transparent border-2 border-[transparent] hover:border-2 hover:border-white shadow-md">
                <Link to="/case-studies">
                  <img
                    src={arrowBlack}
                    className="w-4 h-4 group-hover:translate-x-1 transition-all duration-400"
                    alt="arrow_icon group-hover:bg-none"
                  />
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="lg:w-[24%] flex flex-col gap-4">
          <div className="flex flex-col gap-2 rounded-3xl border bg-[#ddd] my-4">
            <img src={s2i1} className="rounded-3xl" alt="case-studies" />
            <div className="p-2 lg:mb-[7.8rem]">
              <p className="text-[1.2rem] font-semibold font-proximaNovaExtraBold">
                Sales CRM Software
              </p>
              <p className="text-[.9rem] font-montserrat">
                For the enhancement of your sales through the CRM we provide an
                advanced sales CRM that can accumulate all the data including
                leads, current status and all.
              </p>
              <button className="mt-2 rounded-[100%] bg-white p-1 group hover:bg-transparent border-2 border-[transparent] hover:border-2 hover:border-white shadow-md">
                <Link to="/case-studies">
                  <img
                    src={arrowBlack}
                    className="w-4 h-4 group-hover:translate-x-1 transition-all duration-400"
                    alt="arrow_icon group-hover:bg-none"
                  />
                </Link>
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2 rounded-3xl border bg-[#ddd]">
            <img src={s2i2} className="rounded-3xl" alt="case-studies" />
            <div className="p-2 lg:mb-[7.8rem]">
              <p className="text-[1.2rem] font-semibold font-proximaNovaExtraBold">
                HRMS Software
              </p>
              <p className="text-[.9rem] font-montserrat">
                We work for the HRMS software that can track the complete
                records of employees including attendance and other factors like
                leave and all.
              </p>
              <button className="mt-2 rounded-[100%] bg-white p-1 group hover:bg-transparent border-2 border-[transparent] hover:border-2 hover:border-white shadow-md">
                <Link to="/case-studies">
                  <img
                    src={arrowBlack}
                    className="w-4 h-4 group-hover:translate-x-1 transition-all duration-400"
                    alt="arrow_icon group-hover:bg-none"
                  />
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="lg:w-[24%] flex flex-col gap-4">
          <div className="flex flex-col gap-2 rounded-3xl border bg-[#ddd] my-4">
            <img src={s3i1} className="rounded-3xl" alt="case-studies" />
            <div className="p-2 my-2">
              <p className="text-[1.2rem] font-semibold font-proximaNovaExtraBold">
                AI & Meta
              </p>
              <p className="text-[.9rem] font-montserrat">
                We have been working on AI-enabled services for automated
                services so that you can get a fast response from the customers
                willing to visit your site.
              </p>
              <button className="mt-2 rounded-[100%] bg-white p-1 group hover:bg-transparent border-2 border-[transparent] hover:border-2 hover:border-white shadow-md">
                <Link to="/case-studies">
                  <img
                    src={arrowBlack}
                    className="w-4 h-4 group-hover:translate-x-1 transition-all duration-400"
                    alt="arrow_icon group-hover:bg-none"
                  />
                </Link>
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2 rounded-3xl border bg-[#ddd] my-4">
            <img src={s3i2} className="rounded-3xl" alt="case-studies" />
            <div className="p-2 my-2">
              <p className="text-[1.2rem] font-semibold font-proximaNovaExtraBold">
                Banking Services
              </p>
              <p className="text-[.9rem] font-montserrat">
                You can integrate your business with the banking services where
                we customize all these as per the services and requirements.
              </p>
              <button className="mt-2 rounded-[100%] bg-white p-1 group hover:bg-transparent border-2 border-[transparent] hover:border-2 hover:border-white shadow-md">
                <Link to="/case-studies">
                  <img
                    src={arrowBlack}
                    className="w-4 h-4 group-hover:translate-x-1 transition-all duration-400"
                    alt="arrow_icon group-hover:bg-none"
                  />
                </Link>
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2 rounded-3xl border bg-[#ddd] my-4">
            <img src={s3i3} className="rounded-3xl" alt="case-studies" />
            <div className="p-2 my-2">
              <p className="text-[1.2rem] font-semibold font-proximaNovaExtraBold">
                Digital Marketing
              </p>
              <p className="text-[.9rem] font-montserrat">
                Our specialization is there in full-fledged digital marketing
                including all its components like social media marketing, SEO,
                Email marketing, linked in Marketing etc.
              </p>
              <button className="mt-2 rounded-[100%] bg-white p-1 group hover:bg-transparent border-2 border-[transparent] hover:border-2 hover:border-white shadow-md">
                <Link to="/case-studies">
                  <img
                    src={arrowBlack}
                    className="w-4 h-4 group-hover:translate-x-1 transition-all duration-400"
                    alt="arrow_icon group-hover:bg-none"
                  />
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudies;
