import React from "react";
import "./CrmPageContent.css";
import { Link } from "react-router-dom";
import fintech_crm from "../../media/fintech_crm.webp";
import tick_icon from "../../media/tick_icon.png";
import blue_arrow from "../../media/blue_arrow.jpg";
import Cards from "../CommonComponents/Cards/Cards";
import crm_communication from "../../media/crm_communication.webp";
import crm_automation from "../../media/Process-automation.webp";
import software_integration from "../../media/Software-Integration.webp";
import email_feature from "../../media/f01.png";
import lead_feature from "../../media/f02.png";
import varification_feature from "../../media/f03.png";
import tracking_feature from "../../media/f04.png";
import sales_workflow_feature from "../../media/f05.png";
import notification_feature from "../../media/f06.png";
import dynamic_form_feature from "../../media/f07.png";
import sales_regimentation_feature from "../../media/f08.png";
import workday_planning_feature from "../../media/f09.png";
import Report_feature from "../../media/f10.png";
import apis_feature from "../../media/f11.png";
import lead_management from "../../media/lead_management.webp";
import e2e_fintech_crm from "../../media/e2e_fintech_crm.webp";
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import FullWidthCard from "../CommonComponents/Cards/FullWidthCard";
import SectionCard from "../CommonComponents/SectionCard";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";

function CrmPageContent() {
  const crm_featured_item = [
    {
      heading: "Email, WhatsApp, SMS",
      icon: email_feature,
      desc: "Send automated replies and create bulk messages.",
    },
    {
      heading: "Lead scoring",
      icon: lead_feature,
      desc: "Evaluate the lead quality with a brief analysis.",
    },
    {
      heading: "Online Verification",
      icon: varification_feature,
      desc: "Customized online verification and KYC facilities.",
    },
    {
      heading: "Tracking",
      icon: tracking_feature,
      desc: "Monitor and track all the leads coming from the CRM.",
    },
    {
      heading: "Sales workflows",
      icon: sales_workflow_feature,
      desc: "Accelerate the workflows with the automated system. ",
    },
    {
      heading: "Notifications",
      icon: notification_feature,
      desc: "Always keep you alert with reminders for multiple tasks.",
    },
    {
      heading: "Role-based permissions",
      icon: varification_feature,
      desc: "Provide access as per the designation and role.",
    },
    {
      heading: "Dynamic forms",
      icon: dynamic_form_feature,
      desc: "Dynamic forms that always help to maintain the records.",
    },
    {
      heading: "Sales regimentation",
      icon: sales_regimentation_feature,
      desc: "Maintains all the limitations while tracking achievements and rewards.",
    },
    {
      heading: "Workday planning",
      icon: workday_planning_feature,
      desc: "Set the work planning for upcoming services and responsibilities.",
    },
    {
      heading: "Reports",
      icon: Report_feature,
      desc: "Maintains the explicit information on your dashboard.",
    },
    {
      heading: "APIs",
      icon: apis_feature,
      desc: "Integrate with the different apps and sources for better results.",
    },
  ];
  const DemoCtaText =
    "We transform your dream of streamlining your business online into reality. Let’s dive into the world of IT and digital marketing solutions with our expertise.";

  let scrollValue = 4350;
  // window width
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 4350;
  } else {
    scrollValue = 8000;
  }

  return (
    <div className="crm_page_content">
      <section className="crm_page_content_hero">
        <div className="left" data-aos="zoom-in-right">
          <h1 className=" font-proximaNovaExtraBold text-[1.9rem] md:text-[2.1rem] leading-[1.9rem] mt-10 md:mt-5">
            Elevate Your Fintech Business with Ease and Speedy Growth
          </h1>
          <p>
            An effective solution to escalate and enhance the fintech service
            from capturing leads and providing fast, reliable, and transparent
            services during the entire process.
          </p>
          <ul>
            <li>
              <span>
                <img src={tick_icon} alt="img" />
              </span>
              <span id="tick-id">Flexible</span>
            </li>
            <li>
              <span></span>
              <img src={tick_icon} alt="img" />
              <span id="tick-id">Fast Adoption</span>
            </li>
            <li>
              <span></span>
              <img src={tick_icon} alt="img" />
              <span id="tick-id">Great Support</span>
            </li>
          </ul>
          <Link to={"https://www.nextbigbox.io/blogs/"} target="blank">
            What is Fintech CRM. Explore <img src={blue_arrow} alt="img" />
          </Link>
        </div>
        <div className="right crm_dashboard_img" data-aos="zoom-in-left">
          <img src={fintech_crm} alt="img" />
        </div>
      </section>
      <section className="crm_featured">
        <h2 className="f_head font-proximaNovaExtraBold text-center text-[1.9rem] md:text-[2.1rem] leading-[1.9rem]">
          A Featured Powered Fintech CRM
        </h2>
        <div className="crm_featured_item" data-aos="zoom">
          {crm_featured_item.map((item, index) => (
            <Cards
              key={index}
              heading={item.heading}
              icon={item.icon}
              desc={item.desc}
            />
          ))}
        </div>
      </section>
      <section className=" crm_communication crm_communication1">
        <div className="left p-4 " data-aos="zoom-out-right">
          <img src={crm_communication} alt="img" />
        </div>
        <div className="right" data-aos="zoom-out-left">
          <h2 className="font-proximaNovaExtraBold">Communication</h2>
          <p>
            Establish robust communication with Email, SMS, WhatsApp, and all
            that can help your business be ahead.{" "}
          </p>
          <ul>
            <li>Compose Email for approval or rejection.</li>
            <li>Set the reminder for the repayment.</li>
            <li>Easily call to customers with data.</li>
            <li>Crete WhatsApp message for spearing offers.</li>
            <li>Get the updates and loan services.</li>
          </ul>
        </div>
      </section>
      <section className="crm_lead_management1">
        <div className="upper">
          <h2
            className="text-center text-[1.9rem] leading-[1.9rem] font-proximaNovaExtraBold mb-2 mt-2"
            data-aos="zoom-in"
          >
            Manage your leads with complete security
          </h2>
          <p>
            Accomplish multiple services simultaneously on a single application
            with ease and perfection
          </p>
        </div>
        <div className="bottom">
          <div className="left mt-2" data-aos="zoom-in-up">
            <img src={lead_management} className="" alt="img" />
          </div>
          <div className="right" data-aos="zoom-in-down">
            <h2 className="mt-8 md:mt-20 text-center text-[1.9rem] md:text[2.1rem] font-bold md:text-start font-proximaNovaExtraBold">
              Lead Management
            </h2>
            <p>
              It’s capable of managing communication via different sources like
              email, SMS, WhatsApp, and all.
            </p>
            <ul>
              <li>Call and record the conversation with a simple click.</li>
              <li>Easily save and compose message templates.</li>
              <li>Customize multi-channel auto responders.</li>
              <li>
                Tailor messages using the lead information stored in your sales
                CRM.
              </li>
              <li>A precise track engagement matrix.</li>
              <li>
                Easy for the documentation with the complete history of the
                lead.
              </li>
            </ul>
            <Link
              className="reading_page_link"
              to={"https://www.nextbigbox.io/blogs/"}
              target="blank"
            >
              What is Fintech CRM. Explore <img src={blue_arrow} alt="img" />
            </Link>
          </div>
        </div>
      </section>
      <FullWidthCard
        heading={"End-to-end process of Fintech CRM"}
        subheading={
          "Let’s provide an outstanding experience to your entire team with fintech CRM"
        }
        img={e2e_fintech_crm}
      />
      <CtaComponent
        description={DemoCtaText}
        scrollVal={scrollValue}
        scrollDuration={100}
      />
      <section className="crm_automation process_automation">
        <div className="left" data-aos="zoom-out-right">
          <h2 className="mt-10 text-center text-[1.9rem] md:text-[2.1rem] leading-[1.9rem] font-proximaNovaExtraBold">
            Integrated Automation Process
          </h2>
          <p>
            Feel the relevance and creativity of the automated fintech CRM which
            is keen to evaluate the quality of leads that helps to decide and
            choose the quality leads.
          </p>
          <ul>
            <li>• Automatically update the lead information</li>
            <li>• Create the email for approval and rejection.</li>
            <li>• Set the reminder call for repayment.</li>
            <li>• Automatically send a reminder for repayment</li>
          </ul>

          <Link to={"https://www.nextbigbox.io/blogs/"} target="blank">
            What is Fintech CRM. Explore <img src={blue_arrow} alt="img" />
          </Link>
        </div>
        <div className="right mt-8" data-aos="zoom-out-left">
          <img src={crm_automation} alt="img" />
        </div>
      </section>
      <section className="crm_automation crm_automation_integration">
        <div className="right pt-0" data-aos="zoom-out-right">
          <img src={software_integration} className="w-[70%] ml-8" alt="img" />
        </div>
        <div className="left software-integration" data-aos="zoom-out-left">
          <h2 className="text-[1.9rem] md:text-[2.1rem] font-proximaNovaExtraBold">
            Software Integration
          </h2>
          <p className="font-montserrat">
            Perform multiple integrated functions using different Apps like
            message, email, outlook, Aadhaar verification, Pan Verification
            video KYC and all. Accomplish all your integration services with
            ease using sales CRM.
          </p>
          <ul className="my-feature">
            <ul>
              <li>Message Integration</li>
              <li>G-Mail Integration</li>
              <li>Outlook Integration</li>
            </ul>
            <ul>
              <li>Pan Verification</li>
              <li>Aadhaar Verification</li>
              <li>Custome API Integration</li>
            </ul>
          </ul>

          <Link to={"https://www.nextbigbox.io/blogs/"} target="blank">
            What is Fintech crm. Explore <img src={blue_arrow} alt="img" />
          </Link>
        </div>
      </section>

      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent />
      </SectionCard>
    </div>
  );
}

export default CrmPageContent;
