import React from "react";
import "./SocialMediaMarketingContent.css";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
import smm from "../../media/social-media-marketing-hero.webp";
import facebook_icon from "../../media/fb_icon.svg";
import youtube from "../../media/youtube.svg";
import linkedin from "../../media/linkedin.svg";
import instagram from "../../media/instagram.svg";
import stratagy from "../../media/stratagy.webp";
import research from "../../media//creatives.webp";
import community from "../../media/Community-Management.webp";
import anylytics from "../../media/Analytics.webp";
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";

function SocialMediaMarketingContent() {
  const DemoCtaText =
    "We transform your dream of streamlining your business online into reality. Let’s dive into the world of IT and digital marketing solutions with our expertise.";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 1900;
  } else {
    scrollValue = 4520;
  }
  return (
    <div>
      <div className="sp4_main">
        <section className="sp4s1">
          <div className="left">
            <span className="text-lg font-semibold">
              Social Media Marketing
            </span>
            <h3 className="font-proximaNovaExtraBold leading-[2.2rem] text-[2.2rem] w-[90%]">
              How Social Media Marketing Boost Brand Visibility
            </h3>
            <p>
              Our <b>social media marketing </b> strategies are the best
              component to enhance your brand visibilities in a wider area of
              customer so that in a limited period, your product can pursue the
              journey of business to brand.
            </p>
            <ul>
              <li>Tailored Strategies</li>
              <li>Audience-Centric Approach</li>
              <li>Visibility Optimization</li>
              <li>Engagement and Growth</li>
            </ul>
          </div>
          <div className="right">
            <img src={smm} alt="img" />
          </div>
        </section>
        <section className="sp4s2 mb-2">
          <h2 className="font-proximaNovaExtraBold text-center text-[2.2rem]">
            Targeted Social Media Advertising Campaigns
          </h2>
          <div>
            <div className="card" data-aos="zoom-in">
              <img src={facebook_icon} alt="img" className=" w-8 h-8" />
              <h3 className="font-bold text-[.9rem] mt-1">FaceBook</h3>
              <p>
                Since there are more users on Facebook, we provide social media
                marketing services for Facebook where all the visitors can be
                transformed into potential customers for better revenue
                generation and elevation of business.
              </p>
            </div>
            <div className="card" data-aos="zoom-in">
              <img src={linkedin} alt="img" className=" w-8 h-8" />
              <h3 className="font-bold text-[.9rem] mt-1">LinkedIn</h3>
              <p>
                Considering the requirements of B2B and B2C professionals we
                provide social media marketing services to attract and interact
                with the professionals widely active on LinkedIn.
              </p>
            </div>
            <div className="card" data-aos="zoom-in">
              <img src={instagram} alt="img" className=" w-8 h-8" />
              <h3 className="font-bold text-[.9rem] mt-1">Instagram</h3>
              <p>
                With the trending higher numbers of users, Instagram is also one
                of the best places to ensure maximum numbers of clients and
                similarly to Facebook, we provide transformative services on
                Instagram also.
              </p>
            </div>
            <div className="card" data-aos="zoom-in">
              <img src={youtube} alt="img" className=" w-8 h-8" />
              <h3 className="font-bold text-[.9rem] mt-1">YouTube</h3>
              <p>
                To spread your video ads and visual advertisements our social
                media marketing services use YouTube for widespread brand
                awareness and it helps your business to get a maximum number of
                customers.
              </p>
            </div>
          </div>
        </section>
      </div>
      <CtaComponent
        description={DemoCtaText}
        scrollVal={scrollValue}
        scrollDuration={100}
      />
      <div className="sp4_main sp4_main_middle">
        <section className="sp4s3">
          <h2 className="text-center font-proximaNovaExtraBold text-[2.2rem] pb-4 mb-4 ">
            Your In-House Social Media Management Team
          </h2>
          <div className="card-container w-100 flex justify-center gap-8 ">
            <div className="card" data-aos="zoom-out">
              <img src={stratagy} alt="img" />
              <h3 className="font-bold text-[1.2rem] mt-4 text-center">
                Strategy (Audit + Playbook)
              </h3>
              <p>
                We proceed with the perfect strategy and proper auditing for the
                perfection of social media marketing.
              </p>
              {/* <button className="ml-28 mb-4">Strategy</button> */}
            </div>
            <div className="card" data-aos="zoom-out">
              <img src={research} alt="img" />
              <h3 className="font-bold text-[1.2rem] mt-4 text-center">
                Creative (Strategy + Production)
              </h3>
              <p>
                Our creativity in content and graphics always helps us be
                captivating and attractive to customers.
              </p>
              {/* <button className="ml-28 mb-4"> Content </button> */}
            </div>
            <div className="card" data-aos="zoom-out">
              <img src={community} alt="img" />
              <h3 className="font-bold text-[1.2rem] mt-4 text-center">
                Community Management
              </h3>
              <p>
                With the perfect category and classification, we always choose
                the wider community for the efficient result.
              </p>
              {/* <button className="ml-12 mb-4">Community Management</button> */}
            </div>
            <div className="card" data-aos="zoom-out">
              <img src={anylytics} alt="img" />
              <h3 className="font-bold text-[1.2rem] mt-4 text-center">
                Analytics / Reporting
              </h3>
              <p>
                With a profound analysis and reporting our social marketing
                services help you ensure higher numbers of customers.
              </p>
              {/* <button className="ml-28 mb-4">Analytics</button> */}
            </div>
          </div>
        </section>
      </div>
      <ContactComponent />
    </div>
  );
}

export default SocialMediaMarketingContent;
