import React from "react";
import "./PPCPageContent.css";
import CtaComponent from "../CommonComponents/CtaComponent/CtaComponent";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
import SectionCard from "../CommonComponents/SectionCard";
import ppc_audit from "../../media/ppc-audit.webp";
import ad_creative from "../../media/ad_creative.webp";
import first_party_data_activation from "../../media/first_party_data_activation.webp";
import full_funnel_roi_tracking from "../../media/full_funnel_roi_tracking.webp";
import bid_management from "../../media/bid_management.webp";
import landing_page_optimization from "../../media/landing_page_optimization.webp";
import ppc_banner from "../../media/ppc_banner.jpeg";
import geofensing_ad_servicesing from "../../media/geofensing_ad_servicesing.webp";
import ppc_for_ama_fli from "../../media/ppc_for_ama_fli.webp";
import social_media_ad_servicing from "../../media/social_media_ad_servicing.webp";
import remarketing_services from "../../media/remarkegin_services.webp";
import reporting_consistency from "../../media/reporting_consistency_icon.webp";
import accelerate_roi_icon from "../../media/accelerate_roi_icon.webp";
import grow_bottom_line_icon from "../../media/grow_bottom_line_icon.webp";
import streamline_stratagy_icon from "../../media/streamline_stratagy_icon.webp";
import FaqSectionComponent from "../CommonComponents/FaqSectionComponent/FaqSectionComponent";

function PPCPageContent() {
  const faqData = [
    // Define your FAQ data here with questions and answers
    {
      q: "What are Pay-per-click services?",
      a: "It’s the most efficient digital marketing service that helps you grow your business with extended customer reach, increased website traffic and many more things. ",
    },
    {
      q: "What can be the benefits of associating with the PPC services?",
      a: "It will enhance your customer reach with the appearance on the first page and probably prompt your audience to engage with your services.",
    },
    {
      q: "What are the charges for the PPC services?",
      a: "It will depend on your budget and the range on which you will be able to set the campaign for better revenue growth. ",
    },
    {
      q: "Is it limited to some areas?",
      a: "You will have the freedom to choose a particular area so that you can target your audience.",
    },
    {
      q: "How can I Choose the best PPC services?",
      a: "With the perfect portfolio and experience, you can choose the best digital marketing agency. However, we are there with our experts.",
    },
  ];
  const demoText =
    "We transform your dream of streamlining your business online into reality. Let’s dive into the world of IT and digital marketing solutions with our expertise.";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 3550;
  } else {
    scrollValue = 8200;
  }
  return (
    <div>
      <div className="main_ppc_page_content">
        <section className="sp2s1">
          <div className="left" style={{ marginTop: "4rem" }}>
            <img src={ppc_banner} className="md:pt-6" alt="img" />
          </div>
          <div className="right">
            <h1>Exponentially Grow Your Sales with PPC Services</h1>
            <p>
              If you are willing to enhance your business growth via digital
              marketing services to get speedy growth then the Pay-per-click
              service is the most effective component which is capable of
              getting targeted audiences, increased brand recognition and
              values, increased website traffic, sales, leads and all. These
              phenomena of the PPC campaign will help you enhance your business
              with growth and all.
            </p>
            <p>
              Our dedicated team and experts always undertake your projects with
              keen monitoring and efficient outcomes. With strategic services,
              we always use our specific techniques and profound experience to
              accomplish these services and to help you reach at the goal
              easily.
            </p>
          </div>
        </section>
        <section className="sp2s2 mt-16">
          <h2
            className="font-proximaNovaExtraBold text-center text-[2.2rem] mb-4 leading-[2.2rem]"
            data-aos="fade-up"
          >
            Distinct and exclusive features of Best PPC Services
          </h2>
          <p className="px-4" data-aos="fade-up" data-aos-offset="20">
            We provide a comprehensive and effective PPC service that includes
            all the features like research, execution, monitoring, and all which
            is the key to the success of our digital marketing, especially the
            PPC campaign.
          </p>
          <ul>
            <li
              data-aos="fade-right"
              className="card_body "
              data-aos-offset="20"
            >
              <b>PPC Audit</b>
              <img src={ppc_audit} className="cardImg1 " alt="img" />
            </li>
            <li data-aos="fade-up" className="card_body" data-aos-offset="20">
              <b>Ads Creatives</b>
              <img src={ad_creative} className="cardImg1" alt="img" />
            </li>
            <li data-aos="fade-left" className="card_body" data-aos-offset="20">
              <b>First-Party Data Activation</b>
              <img
                src={first_party_data_activation}
                className="cardImg1"
                alt="img"
              />
            </li>
            <li data-aos="fade-right" className="card_body">
              <b>Full-Funnel ROI Tracking</b>
              <img
                src={full_funnel_roi_tracking}
                className="cardImg1"
                alt="img"
              />
            </li>
            <li data-aos="fade-up" className="card_body">
              <b>Bid Management</b>
              <img src={bid_management} className="cardImg1" alt="img" />
            </li>
            <li data-aos="fade-left" className="card_body">
              <b>Landing Page Optimization</b>
              <img
                src={landing_page_optimization}
                className="cardImg1"
                alt="img"
              />
            </li>
          </ul>
        </section>
      </div>
      <CtaComponent
        description={demoText}
        scrollVal={scrollValue}
        scrollDuration={100}
      />
      <div className="main_ppc_page_content main_ppc_page_content_middle">
        <section className="sp2s3">
          <div className="upper">
            <h2 className="font-proximaNovaExtraBold text-center text-[2.2rem]">
              What is PPC in digital marketing
            </h2>
          </div>
          <div className="bottom">
            <div className="card" data-aos="fade-in" data-aos-delay="00">
              <img src={reporting_consistency} alt="img" />
              <h3 className="font-bold text-[1.2rem]">
                Consistency in Reporting
              </h3>
              <p className="">
                As we always say, we have frequent monitoring and auditing for
                the outcome of our services, which helps us get insight into the
                betterment of the service and its outcome. However, while
                running the <b>Best pay-per-click</b> campaign, our executive
                always interacts and suggests for a better result with a
                positive implementation.
              </p>
            </div>
            <div className="card" data-aos="fade-in" data-aos-delay="500">
              <img src={accelerate_roi_icon} alt="img" />
              <h3 className="font-bold text-[1.2rem]">Accelerate your ROI</h3>
              <p>
                Since our experts are always available and use their perfection
                and expertise while implementing the Pay-Per-Click campaign, the
                visibility and recognition of your website get increased, and
                the targeted audiences and their engagement helps in growing the
                revenue.
              </p>
            </div>
            <div className="card" data-aos="fade-in" data-aos-delay="1000">
              <img src={grow_bottom_line_icon} alt="img" />
              <h3 className="font-bold text-[1.2rem]">Grow your bottom line</h3>
              <p>
                Your overall financial growth is ensured with the widespread of
                customer deals and engagement, and therefore your business and
                its overall strength will be enhanced with the maximum profit
                which is a sufficient reason to grow the bottom line with the
                overall financial strength.
              </p>
            </div>
            <div className="card" data-aos="fade-in" data-aos-delay="1500">
              <img src={streamline_stratagy_icon} alt="img" />
              <h3 className="font-bold text-[1.2rem]">
                Streamline Your Strategy
              </h3>
              <p>
                With effective consequences and beneficial results of the
                Pay-Per-Click campaign, your business can be streamlined and
                flourish with the maximum outcomes. Therefore, you can establish
                your business as an online platform through our service and can
                be the best choice for your target audience.
              </p>
            </div>
          </div>
        </section>
        <section className="api_section_2">
          <h3 className="font-proximaNovaExtraBold text-center md:text-center text-[2.2rem] leading-[2.2rem] ">
            Our PPC Ad Services in its Different Steps
          </h3>
          <div className="api_card_container">
            <div className="api_card" data-aos="fade-up">
              <div className="left">
                <h4 className="font-bold text-[1.2rem]">
                  Remarketing services
                </h4>
                <p>
                  You can connect with your prior website visitors for better
                  engagement and enhanced sales so that the revenue can be
                  elevated, and customer relations can be enriched.
                </p>
              </div>
              <div className="right ml-3 services_cards">
                <img src={remarketing_services} alt="img" />
              </div>
            </div>
            <div className="api_card" data-aos="fade-up">
              <div className="left">
                <h4 className="font-bold text-[1.2rem]">Geofencing Services</h4>
                <p>
                  With the geofencing service where the PPC AD services are
                  confined to a limited area to enhance the density of the
                  customers for perfection and all.
                </p>
              </div>
              <div className="right services_cards">
                <img src={geofensing_ad_servicesing} alt="img" />
              </div>
            </div>
            <div className="api_card" data-aos="fade-up">
              <div className="left">
                <h4 className="font-bold text-[1.2rem]">
                  Social Media Ad services
                </h4>
                <p>
                  Pay-per-click services can be implemented for social media
                  campaigns also like as LinkedIn, Facebook, Instagram and other
                  social media platforms for better customer reach.
                </p>
              </div>
              <div className="right services_cards">
                <img src={social_media_ad_servicing} alt="img" />
              </div>
            </div>
            <div className="api_card" data-aos="fade-up">
              <div className="left">
                <h4 className="font-bold text-[1.2rem]">
                  PPC for Amazon, Flipkart and other E-commerce
                </h4>
                <p>
                  In the growing, trending online marketing service that is
                  being streamlined on online platforms,{" "}
                  <b>Best result of PPC 2024</b> help to establish your business
                  through Amazon and Flipkart as well.
                </p>
              </div>
              <div className="right services_cards">
                <img src={ppc_for_ama_fli} alt="img" />
              </div>
            </div>
          </div>
        </section>
        <section className="sp2s4 mt-16 mb-8">
          <FaqSectionComponent faqData={faqData} />
        </section>
        <SectionCard
          position={"relative"}
          height={"h-auto"}
          width={"w-400"}
          backgroundColor={"bg-red"}
          color={"text-yellow"}
          className={"text-slate-800 mt-20"}
        >
          <ContactComponent padding={true} />
        </SectionCard>
      </div>
    </div>
  );
}

export default PPCPageContent;
