import React from "react";
import "./Cards.css";

function Cards({ heading, icon, desc, className }) {
  return (
    <div className="card border service_card">
      <img src={icon} alt="img" draggable="false" />
      <h3 className="text-center text-[1.2rem] mb-2 font-bold font-montserrat leading-[1.5rem]">
        {heading}
      </h3>
      <p className={className}>{desc}</p>
    </div>
  );
}

export default Cards;
