import React from "react";
import "./EnterPriseSeoContent.css";
import strategicApproach from "../../media/strategic-approac-icon.svg";
import expertGuidance from "../../media/expert-guidance.svg";
import continuousMonitoring from "../../media/continuous-monitoring.svg";
import assuredResult from "../../media/assured-result.svg";
import customerSatisfaction from "../../media/customer-satisfaction.svg";
import enterpriseSeoHero from "../../media/enterprise_seo.webp";
import SectionCard from "../CommonComponents/SectionCard";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
import resultOrientedSeo from "../../media/result-oriented-enterprice-seo.webp";
import FaqSectionComponent from "../CommonComponents/FaqSectionComponent/FaqSectionComponent";
function EcommerceSeoContent() {
  const faqData = [
    // Define your FAQ data here with questions and answers
    {
      q: "What’s the meaning of enterprise SEO?",
      a: "It’s a strategic SEO service that enhances the appearance of an enterprise along with all its products and services.",
    },
    {
      q: "What is the difference between Enterprise and other SEO?",
      a: "Enterprise SEO differs from other SEO services because of its profound requirements like boosting the page speed and optimizing each page and product separately.",
    },
    {
      q: "Why do bigger organizations and services need Enterprise SEO?",
      a: "It’s all about focusing on each segment and product delivered by the organization, therefore, enterprise SEO is required for the large organization.",
    },
    {
      q: "How can it be beneficial for our services?",
      a: "Accomplishing the goal of business that is nothing but enhancing the traffic and customer reach.",
    },
    {
      q: "What are the essential challenges in Enterprise SEO?",
      a: "It’s little bit tough and lengthy because of including every page and separate descriptions for all the pages.",
    },
  ];
  const faqHeading = "";
  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 2950;
  } else {
    scrollValue = 6050;
  }
  const ScrollDown = (scrollVal, scrollDuration) => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 500) {
      let val = scrollVal - window.scrollY;
      setTimeout(() => {
        window.scrollBy({ top: val, letf: 0, behavior: "smooth" });
        // console.log('val',val);
      }, scrollDuration);
    } else {
      let val = scrollVal - window.scrollY;
      setTimeout(() => {
        window.scrollBy({ top: val, letf: 0, behavior: "smooth" });
        // console.log(val);
      }, scrollDuration);
    }
  };

  return (
    <>
      <div className="enterprise_seo">
        <section className="sp10s1 ">
          <div className="left mt-8">
            <h1>Why Your Organization Needs Enterprise SEO?</h1>
            <p>
              If you are willing to ensure success with higher revenue
              generation and better customer reach then you must consider the
              prominent benefits of Enterprise SEO such as{" : "}
            </p>
            <p>
              <b>Higher Scalability :</b> A comprehensive{" "}
              <b> Best Enterprise SEO Services </b>helps your website to be
              visible frequently as per the search of customers' needs and helps
              to stand with all the precision and parameters imparting
              authoritativeness.
            </p>
            <p>
              <b>Competitive Advantage :</b> Your website gets instant
              visibility with specific page speed and all the specifications
              searched by the customers and as per their requirements, your
              website gets higher organic traffic enhancing your business and
              customer reach.
            </p>
            <p>
              <b>Maximum Revenue :</b> Since a greater number of customers get
              to interact and connect with your website and through that
              directly reach the enterprise. It certainly enhances your business
              and increases revenue in a short period. Therefore, your business
              gets an expansion.
            </p>
          </div>
          <div className="right">
            <img src={enterpriseSeoHero} className="mt-12" alt="img" />
          </div>
        </section>
        <section className="sp10s2">
          <div className="left">
            <img className="w-[500px]" src={resultOrientedSeo} alt="img" />
          </div>
          <div className="right mt-6">
            <h2 className="font-proximaNovaExtraBold text-[2.2rem] leading-[2.2rem]">
              Why Enterprise SEO Services Are Essential in the Digital Era{" "}
            </h2>
            <p>
              In the current time or digital era, approximately fifty per cent
              of website traffic comes with organic search and more than 93% of
              searches are ensured through online searches therefore, enterprise
              SEO is beneficial for all those willing to streamline their
              business at online platform with the higher revenue through the
              customers reach. So <b>Enterprise SEO</b> is beneficial for your
              small business also where all your products along with your
              enterprise are listed with the appropriate keyword and all so that
              you can have extended customer reach.
            </p>
            <p>Interested in making your website Enterprise SEO-friendly.</p>
            <button
              onClick={() => {
                ScrollDown(scrollValue, 100);
              }}
            >
              Get Quotation
            </button>
          </div>
        </section>
        <section className="sp10s3">
          <h2 className="font-proximaNovaExtraBold leading-[2.2rem] text-center text-[2.2rem]">
            What is the specification of our Enterprise SEO?
          </h2>
          <ul>
            <li>
              <h3 className="font-bold text-center text-[1.3rem]">
                Traditional SEO
              </h3>
              <ol>
                <li>1. It targets small to medium-business. </li>
                <li>2. less effective and target normal SEO services.</li>
                <li>3. Initially focus on page.</li>
                <li>4. Deals with limited opportunity. </li>
                <li>5. Often handled by a small team.</li>
                <li>6. It manages small website.</li>
              </ol>
            </li>
            <li>
              <h3 className="text-center font-bold text-[1.3rem]">
                Enterprise SEO
              </h3>
              <ol>
                <li>1. It offers an extensive and wider area of website. </li>
                <li>2. Targets a huge number of clients. </li>
                <li>3. Multiple pages with product listing.</li>
                <li>4. Deals in the wider area and enormous clients.</li>
                <li>5. A team of experts are dedicated to the task.</li>
                <li>6. It manages a larger website with multiple pages.</li>
              </ol>
            </li>
          </ul>
        </section>

        <section className="sp8s4">
          <h3 className="font-proximaNovaExtraBold text-center text-[2.2rem] leading-[2.2rem]">
            {" "}
            What does Our Enterprise SEO Include?
          </h3>
          <ul>
            <li className="card">
              <img src={strategicApproach} alt="img" />
              <h4 className="subHead">1. Website Audit</h4>
              <p>
                As an efficient SEO partner, we initiate thorough research of
                your website, requirements, page speed, and keywords related to
                the business and all the required data. Once getting
                confirmation for all the requirements we proceed and perform a
                cyclic audit where we concentrate on all the changes and focus
                on the enhancement of strategy if required.
              </p>
            </li>
            <li className="card">
              <img src={expertGuidance} alt="img" />
              <h4 className="subHead">2. Keyword Research </h4>
              <p>
                It’s our primary function, indeed from where the SEO strategy
                starts and therefore, we focus on the perfect keyword which must
                be related to your business and products. However, it’s a
                continuous process that we implement as per the results and
                responses from the customers.
              </p>
            </li>
            <li className="card">
              <img src={continuousMonitoring} alt="img" />
              <h4 className="subHead">3. Link Building</h4>
              <p>
                To enhance the trustworthiness of your website on the Google
                search engine and to cultivate the appearance of your website,
                we specially implement<b> link building in SEO</b> through the
                link building process, our dedicated team is always there to
                perform the task and to ensure the desired result. Ultimately,
                our team of experts takes all the responsibilities.
              </p>
            </li>
            <li className="card">
              <img src={assuredResult} alt="img" />
              <h4 className="subHead">4. Content Strategy</h4>
              <p>
                As per our vision and work style, we always focus on quality
                rather than quantity and therefore, our{" "}
                <b>content creation strategy</b> always takes appropriate time
                but delivers effective and transformative content that can
                impart a maximum number of traffic and customers as well.
                hereafter, we always measure the growth and proceed with every
                required process.
              </p>
            </li>
            <li className="card">
              <img src={customerSatisfaction} alt="img" />
              <h4 className="subHead">5. Monitoring and Reporting</h4>
              <p>
                As per the current scenario and strategies going on in the
                market and enterprises, we concentrate and deliberate on the
                competitor strategy and their functions which might be the key
                to their success. In terms of achieving a higher rank and
                getting ahead in the competition and ensuring top rank, we,
                thereafter, create planning.
              </p>
            </li>
          </ul>
        </section>
        <section className="sp1s4 mt-24">
          <FaqSectionComponent faqHeading={faqHeading} faqData={faqData} />
        </section>
      </div>
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent />
      </SectionCard>
    </>
  );
}

export default EcommerceSeoContent;
