import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../Components/CommonComponents/Navbar";
import { json, Link, Outlet, useLocation, useParams } from "react-router-dom";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import PageCard from "../Components/CommonComponents/PageCardComponent/PageCard";
import { Border, HexagonFill } from "react-bootstrap-icons";
import FooterForm from "../Components/CommonComponents/FooterForm/FooterForm";
import FaqComponent from "../Components/CommonComponents/FaqComponent/FaqComponent";
import FaqSectionComponent from "../Components/CommonComponents/FaqSectionComponent/FaqSectionComponent";
import IndiaList from "../data/India";
import UkList from "../data/UK";
import { ConfigProvider } from "antd";
import { Select, Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import singaporeList from "../data/Singapore";
import USA from "../data/USA";
import Uae from "../data/Uae";
import { useNavigate } from "react-router-dom";
import Seo from "../Components/CommonComponents/Seo";

const DynamicCategory = ({ country, place }) => {
  const [test, setTest] = useState(false);
  const [fbLeads, setFbLeads] = useState("");
  const location = useLocation();
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };
  useEffect(() => {
    // Get the full URL
    const fullUrl = window.location.href;
    // Parse the URL and query parameters
    const url = new URL(fullUrl);
    const params = new URLSearchParams(url.search);

    // Get the 'utm_source' parameter
    const utmSource = params.get("utm_source");

    // Determine the value for fbLeads based on utmSource
    let leadsValue = "";
    switch (utmSource) {
      case "facebook_organic":
        leadsValue = "Fb Organic";
        break;
      case "Google_CPC":
        leadsValue = "Google CPC";
        break;
      case "instagram_organic":
        leadsValue = "Instagram Organic";
        break;
      case "linkedin_organic":
        leadsValue = "Linkedin Organic";
        break;
      case "PPL_DelhiNCR":
        leadsValue = "PPL Delhi NCR";
        break;
      case "Diwali_Offer":
        leadsValue = "Diwali Offer";
        break;
      case "INSTAGRAM_PAID":
        leadsValue = "INSTAGRAM PAID";
        break;
      case "Whatsapp_API":
        leadsValue = "Whatsapp API";
        break;
      case "Bangalore_data":
        leadsValue = "W Bangalore";
        break;
      case "Phamphlet":
        leadsValue = "Phamphlet";
        break;
      case "Deman_Gen":
        leadsValue = "Demand Gen";
        break;
      case "FACEBOOK":
        leadsValue = "Fb";
        break;
      case "EMAIL":
        leadsValue = "EMAIL";
        break;
      case "SMS":
        leadsValue = "SMS";
        break;
      case "Raw_Data":
        leadsValue = "Raw Data";
        break;
      case "Whatsapp_new":
        leadsValue = "Whatsapp";
        break;
      case "Offer":
        leadsValue = "Offer";
        break;
      case "Bings_Ads":
        leadsValue = "Bings Ads";
        break;
      case "Google_PPLJAN":
        leadsValue = "Google PPLJAN";
        break;
      case "Insta_Inpaid":
        leadsValue = "Insta IN";
        break;
      case "FB_Inpaid":
        leadsValue = "FB IN";
        break;
      case "Whatsapp_In":
        leadsValue = "Whatsapp IN";
        break;
      case "Email_In":
        leadsValue = "Email IN";
        break;
      case "SMS_In":
        leadsValue = "SMS IN";
        break;
      default:
        leadsValue = "nextbigbox.io";
    }
    // Set the fbLeads state
    setFbLeads(leadsValue);
    window.scrollTo(0, 0);
  }, [location.path, place]);

  let list = [];

  switch (country) {
    case "india":
      list = IndiaList;
      break;
    case "uk":
      list = UkList;
      break;
    case "singapore":
      list = singaporeList;
      break;
    case "usa":
      list = USA;
      break;
    case "uae":
      list = Uae;
      break;
    default:
      break;
  }

  const content = list.find((placeList) => placeList.place === place);

  const {
    register,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
  } = useForm();

  const servicesList = [
    {
      label: "Web Design",
      value: "webDesign",
    },
    {
      label: "Social Media Marketing",
      value: "smm",
    },
    {
      label: "Content Marketing",
      value: "contentMarketing",
    },
    {
      label: "Conversion Rate Optimization (CRO)",
      value: "cro",
    },
    {
      label: "PPC Ads",
      value: "ppcAds",
    },
    {
      label: "Photography / Video",
      value: "photography/Video",
    },
    {
      label: "Email Marketing",
      value: "emailMarketing",
    },
    {
      label: "Search Engine Marketing",
      value: "SearchEngineMarketing",
    },

    {
      label: "Link Building",
      value: "linkBuilding",
    },
    {
      label: "Reputation / Reviews Management",
      value: "Reputation/Reviews Management",
    },
    {
      label: "Web Hosting / Maintenance",
      value: "WebHosting/Maintenance",
    },
    {
      label: "Search Engine Optimization",
      value: "seo",
    },

    {
      label: "Google Ads",
      value: "googleAds",
    },
    {
      label: "Web Development",
      value: "webDevelopment",
    },
    {
      label: "Finech CRM",
      value: "fintechCrm",
    },
    {
      label: "HRMS",
      value: "hrms",
    },
    {
      label: "API Integration",
      value: "apiIntegration",
    },
    {
      label: "Fintech Solution",
      value: "fintechSolution",
    },
    // {
    //   label : "",
    //   value : "",
    // },
  ];

  const onSubmit = async (data) => {
    const services = data.service.join(", ");
    const formData = new FormData();
    formData.append("uname", data.userName);
    formData.append("email", data.emailId);
    formData.append("mobile", Number(data.mobileNo));
    formData.append("utmSource", fbLeads);
    formData.append("servicesType", services);
    formData.append("company_url", data.webAddress);
    formData.append("remarks", data.message);
    // formData.append("city", data.city);

    await axios
      .post(
        "https://salescrm.nextbigbox.tech/api/addNewWebsiteLead",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((e) => {
        showToast("success");
      })
      .catch((e) => {
        showToast("error");
      });
    reset();
  };
  // toast message
  const showToast = (status) => {
    switch (status) {
      case "success":
        toast.success("Form Submitted Successfully.", {
          position: "top-right",
          autoClose: 1000, // Close the toast after 1 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      case "error":
        toast.error("Something Went Wrong.", {
          position: "top-right",
          autoClose: 1000, // Close the toast after 1 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      default:
    }
  };

  return (
    <div>
      <Seo
        name={"Nextbigbox"}
        type={"Article"}
        title={content.metaTitle}
        description={content.metaDescription}
        canonical_url={content.canonicalTag}
        metaKeywords={content.metaKeywords}
        geoTargetCode={content.geoTargetCode}
        scriptTag={content.scriptTag}
      />
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      <div className={test ? "hidden" : "visible"}>
        <div className="flex flex-row justify-center items-center w-full h-56 pt-[4rem] bg-gradient-to-b from-sky-400 to-white">
          <h1 className="font-bold leading-[3rem] font-proximaNovaExtraBold text-[3rem] text-center mr-0.7 ml-0.7 md:mr-[2rem] md:ml-[2rem] lg:mx-[5.3rem]  mt-[4rem] md:mt-[2rem]">
            {content.h1}
          </h1>
        </div>

        <div className="px-[2rem] mt-12 lg:px-[5.3rem]">
          <section className="flex flex-row justify-center">
            <div className="flex flex-col just lg:flex-row justify-center items-center lg:items-start gap-10 max-w-[1440px]">
              <div className="w-full lg:w-3/5" data-aos="zoom-in">
                {content.h1_paragraph}
              </div>
              <div className=" w-full md:w-[70%] lg:w-2/5">
                <div className="flex flex-row justify-center items-center">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-[100%] h-auto border-black border-2 rounded-3xl overflow-hidden">
                      <div className="flex flex-row justify-center items-center w-[100%] h-[5rem] bg-black ">
                        <h2 className="text-2xl font-bold text-white text-center p-2">
                          Digital Marketing Proposal
                        </h2>
                      </div>
                      <p className="text-center mt-2">
                        We need a little info to <strong>get started</strong>
                      </p>
                      <div className="m-5">
                        <ConfigProvider
                          theme={{
                            components: {
                              Select: {
                                fontSize: "14",
                                controlBg: "#0000",
                              },
                            },
                          }}
                        >
                          <Controller
                            name="service"
                            control={control}
                            className="custom-input"
                            rules={{
                              required: "Please select at least one service",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                mode="multiple"
                                className="custom-input"
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Please select a service"
                                onChange={(value) => field.onChange(value)}
                                options={servicesList}
                              />
                            )}
                          />
                          {errors.service && (
                            <p role="alert" className="form-error text-red-500">
                              {errors.service.message}
                            </p>
                          )}
                        </ConfigProvider>

                        <div className="flex flex-col md:flex-row gap-0 md:gap-2">
                          <div className="w-full">
                            <input
                              placeholder="Enter your CompanyName"
                              type="text"
                              id="companyName"
                              className="mt-2 p-2 w-full border-2 rounded custom-input"
                              {...register("companyName", {
                                required: true,
                              })}
                            />
                            {errors.companyName && (
                              <span role="alert" className="form-error mb-2">
                                Company Name is required
                              </span>
                            )}
                          </div>

                          <div className="w-full">
                            <input
                              placeholder="Enter your Name"
                              type="text"
                              id="userName"
                              className="mt-2 p-2  w-full border-2 rounded custom-input"
                              {...register("userName", {
                                required: true,
                              })}
                            />
                            {errors.userName && (
                              <span role="alert" className="form-error mb-2">
                                Name is required
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-0 md:gap-2">
                          <div className="w-full">
                            <input
                              placeholder="Enter your Email"
                              type="text"
                              id="emailId"
                              className="mt-2 p-2 w-full border-2 rounded custom-input"
                              {...register("emailId", {
                                required: "email id is required",
                                message: "email id is required",
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: "Invalid email address.",
                                },
                              })}
                            />
                            {errors.emailId && (
                              <span role="alert" className="form-error mb-2">
                                {errors.emailId?.message}
                              </span>
                            )}
                          </div>

                          <div className="w-full">
                            <input
                              placeholder="Enter your MobileNo"
                              type="text"
                              id="mobileNo"
                              className="mt-2 p-2 w-full border-2 rounded custom-input"
                              {...register("mobileNo", {
                                required: true,
                                minLength: {
                                  value: 10,
                                  message:
                                    "Mobile No should be at least 10 digits",
                                },
                                maxLength: {
                                  value: 10,
                                  message:
                                    "Mobile No should be at most 10 digits",
                                },
                              })}
                            />
                            {errors.mobileNo && (
                              <span role="alert" className="form-error mb-2">
                                Mobile is required
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Input for Website Address */}
                        <input
                          placeholder="Website Address? (If you have one)"
                          type="text"
                          id="webAddress"
                          className="mt-2 p-2 w-full border-2 rounded custom-input"
                          {...register("webAddress", { required: true })}
                        />
                        {errors.webAddress && (
                          <span
                            role="alert"
                            className="form-error text-red-500 mb-2"
                          >
                            Website Address is required.
                          </span>
                        )}

                        {/* Textarea for Business Info */}
                        <textarea
                          id="message"
                          className="w-[100%] rounded-md max-h-4 mt-2 custom-input"
                          placeholder="Tell About your business"
                          {...register("message", { required: true })}
                        />
                        {errors.message && (
                          <span
                            role="alert"
                            className="form-error text-red-500"
                          >
                            Business info is required.
                          </span>
                        )}

                        {/* Submit Button */}
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="w-[100%] bg-black text-white rounded-md py-4 mt-4 mb text-xl font-semibold"
                        >
                          {" "}
                          {isSubmitting ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  spin
                                  style={{ color: "white" }}
                                />
                              }
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>

          <section className="flex flex-row justify-center">
            <div className="flex flex-col-reverse lg:flex-row justify-between items-center lg:items-start mt-28 lg:gap-8 max-w-[1440px]">
              <div className="w-full md:w-[70%] lg:w-1/2 " data-aos="zoom-in">
                {content.h2_paragraph_1}
              </div>
              <div className="flex flex-row justify-center items-center w-full md:w-[70%] lg:w-1/2 ">
                <img
                  src={content.topDigitalMarketingImage}
                  alt="Top Digital Marketing"
                  className="w-full h-auto max-w-[550px] object-cover"
                />
              </div>
            </div>
          </section>
          <section className="flex flex-row justify-center">
            <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start mt-20 lg:mt-40 lg:gap-8 max-w-[1440px]">
              <div className="w-full mt-10 lg:mt-0 lg:w-1/2 flex flex-row justify-center items-center">
                <img
                  src={content.bestDigitalMarketingImage}
                  alt="Best Digital Marketing"
                  className="w-full h-auto max-w-[550px] object-cover"
                />
              </div>
              <div className="w-full lg:w-1/2" data-aos="zoom-in">
                {content.h2_paragraph_2}
              </div>
            </div>
          </section>
          <section>
            {content.h3_paragraph}
            <div className="flex flex-wrap mt-20 gap-4 justify-center">
              {content.h3_list.map((data, index) => (
                <PageCard
                  key={index}
                  title={data.title}
                  content={data.content}
                />
              ))}
            </div>
          </section>
        </div>
        <FooterForm />
        <FaqSectionComponent
          faqData={content.faqData}
          faqHeading={content.faqHeading}
        />
        <NewFooterComponent />
      </div>
    </div>
  );
};

export default DynamicCategory;
