import React from "react";
import "./EcommerceSeoContent.css";
import ecommerceSeoHero from "../../media/e-commerce-hero.webp";
import ecommerceSeoProcess from "../../media/e-commerce-seo-process.webp";
import SectionCard from "../CommonComponents/SectionCard";
import ContactComponent from "../CommonComponents/ContactComponent/ContactComponent";
import FaqSectionComponent from "../CommonComponents/FaqSectionComponent/FaqSectionComponent";
function EcommerceSeoContent() {
  const faqData = [
    // Define your FAQ data here with questions and answers
    {
      q: "How e-commerce SEO is different from others?",
      a: "It is different from others in terms of strategy and all, where distinct techniques are used for SEO.",
    },
    {
      q: "How can it enhance the business?",
      a: "With multiple features and optimisation like website design, content, and all, it escalates the appearance of your products on the Google search engine.",
    },
    {
      q: "What are the strategies followed to accomplish E-commerce SEO?",
      a: "To accomplish E-commerce SEO in a transformative way, we optimize the website content, images, page linking and all.",
    },
    {
      q: "Do the E-commerce SEO services take a long time to flourish?",
      a: "It’s not an overnight journey and it takes some time especially to enhance the ranking.",
    },
    {
      q: "How can I get an effective E-commerce SEO service for my business?",
      a: "As a reputed E-commerce SEO service, we are there as a leading agency where you get all the solutions, especially those related to the services.",
    },
  ];
  const faqHeading =
    "Let’s solve all your queries related to E-commerce SEO services.";

  // window width
  let scrollValue = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth > 500) {
    scrollValue = 2350;
  } else {
    scrollValue = 4750;
  }
  const ScrollDown = (scrollVal, scrollDuration) => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 500) {
      let val = scrollVal - window.scrollY;
      setTimeout(() => {
        window.scrollBy({ top: val, letf: 0, behavior: "smooth" });
        // console.log('val',val);
      }, scrollDuration);
    } else {
      let val = scrollVal - window.scrollY;
      setTimeout(() => {
        window.scrollBy({ top: val, letf: 0, behavior: "smooth" });
        // console.log(val);
      }, scrollDuration);
    }
  };

  return (
    <>
      <div className="ecommerce_main">
        <section className="sp9s1">
          <div className="left">
            <h1>Increase Product Ranking with Our E-commerce SEO Services</h1>
            <p>
              If you are willing to increase the sales and customer reach for
              your E-commerce seo services and want to enhance revenue
              generation shortly then we are one of the best choices for your
              E-commerce store because with our techniques and robust strategy,
              we always keep your website and products ahead of all your
              competitors.
            </p>
            <p>
              We implement <b>E-commerce SEO Services</b> with all the advanced
              and required techniques like product listing, description, content
              optimization and all. We focus on the highest visibility and
              appearance of all your products listed on the website with the
              best keyword research and implementation strategy.
            </p>
            <button
              onClick={() => {
                ScrollDown(scrollValue, 100);
              }}
            >
              Speak To Our Experts
            </button>
          </div>
          <div className="right">
            <img src={ecommerceSeoHero} alt="img" />
          </div>
        </section>
        <section className="sp9s2">
          <div className="left">
            <img src={ecommerceSeoProcess} alt="img" />
          </div>
          <div className="right">
            <h2 className="font-proximaNovaExtraBold text-[1.9rem] md:text-[2.1rem] leading-[1.9rem]">
              How we elevate and transform your business with our E-Commerce
              SEO?{" "}
            </h2>
            <ul>
              <li>
                <b>1. Identify your Goals and a way to meet them :</b> Before
                initiating the E-commerce SEO service, we get a brief overview
                of your website, business goal and target, and thereafter, we
                perform Our <b>Best E-commerce SEO services</b> so that can
                ensure the ranking of your website and products shortly.
                However, some various parameters and techniques help you be
                ahead of all your competitors with our strategies.
              </li>
              <li>
                <b>2. Website optimization with different techniques : </b>
                Website optimization always matters especially for E-Commerce
                SEO, because unless we optimize the infrastructure of the
                website and specific product listing it can’t be ready for the
                proper search engine optimization.
              </li>
              <li>
                <b>
                  3. The transformation from qualitative-to-qualitative result :{" "}
                </b>
                Getting leads may be easy with the different digital marketing
                services but transformation of leads takes place where the
                content optimization is done appropriately. And we focus on
                quality rather than only quality.
              </li>
            </ul>
          </div>
        </section>
        <section className="sp9s3">
          <h2 className="text-center font-proximaNovaExtraBold text-[1.9rem] md:text-[2.1rem] leading-[1.9rem]">
            Elements For Successful Ecommerce SEO
          </h2>
          <ul>
            <li>
              <h3 className="font-bold text-center text-[1.1rem]">
                Targeted Keyword Research
              </h3>
              <p>
                Considering your products and websites, we always implement
                better keyword research as per the contemporary search on Google
                and our strategy helps you fascinate enormous customers.
              </p>
            </li>
            <li>
              <h3 className="font-bold text-center text-[1.1rem]">
                Blog Content
              </h3>
              <p>
                Focusing on the blog content we always try to create awareness
                through the proper keyword, title, meta description and all so
                that the product information.
              </p>
            </li>
            <li>
              <h3 className="font-bold text-center text-[1.1rem]">
                Website Structure
              </h3>
              <p>
                Before initiating the <b>E-commerce SEO service </b>, we suggest
                for appropriate website structure for your new website, or we
                also suggest changes for the existing one if required as per the
                need.
              </p>
            </li>
            <li>
              <h3 className="font-bold text-center text-[1.1rem]">
                Positive Relationship
              </h3>
              <p>
                A positive relationship is our priority, and we always focus on
                a robust relationship strategy therefore we always focus on
                these concerns and always try to enhance the connectivity with
                the customers.
              </p>
            </li>
            <li>
              <h3 className="font-bold text-center text-[1.1rem]">
                Identifying Problems
              </h3>
              <p>
                Our team always try to identify the actual problems that come in
                between E-commerce <b>SEO services</b>, and once the problem is
                identified, it is resolved easily.
              </p>
            </li>
            <li>
              <h3 className="font-bold text-center text-[1.1rem]">
                Repeated Keyword Research
              </h3>
              <p>
                It’s a repetitive task that is always implemented in the SEO
                service to get a better result and therefore our team always be
                active as per the audit and analytic report.
              </p>
            </li>
          </ul>
        </section>
      </div>
      <div className="faq_container">
        <section className="sp1s4 mt-32">
          <FaqSectionComponent faqHeading={faqHeading} faqData={faqData} />
        </section>
      </div>
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
      >
        <ContactComponent />
      </SectionCard>
    </>
  );
}

export default EcommerceSeoContent;
