import React from "react";
import NotFoundImage from "../../media/404_NotFound.svg";
import { useNavigate } from "react-router-dom";

function NotFoundPage() {
  const nav = useNavigate();
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        {/* <h1 className="text-[10rem] lg:text-[14rem] 2xl:text-[30rem] font-proximaNova text-red-600 ">
          404
        </h1> */}
        <img src={NotFoundImage} alt="404NotFound" />
        <p className="font-montserrat text-base font-semibold px-1">
          Oops! The page you're looking for doesn't exist.
        </p>
        {/* <button className="text-red-600 font-semibold text-base">
          Go back to Homepage
        </button> */}
        <button
          className="font-montserrat font-semibold bg-blue-500 text-white hover:cursor-pointer px-4 py-2 rounded-3xl hover:bg-blue-600 shadow-md mt-4"
          onClick={() => nav(-1)}
        >
          {" "}
          Back to HomePage
        </button>
      </div>
    </div>
  );
}

export default NotFoundPage;
