import React, { useEffect, useState } from "react";
import "../Components/ConditionStyle.css";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/CommonComponents/Navbar";
import SectionCard from "../Components/CommonComponents/SectionCard";
import NewFooterComponent from "../Components/CommonComponents/NewFooterComponent/NewFooterComponent";
import Seo from "../Components/CommonComponents/Seo";

function PrivacyPolicy() {
  const [test, setTest] = useState(false);
  const isNavbarOpened = () => {
    setTest((prev) => !test);
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Seo
        title={"Privacy Policy | Nextbigbox "}
        description={"Top It Solution in Delhi NCR."}
        name={"Nextbigbox"}
        type={"Article"}
      />
      {/* Navbar Start  */}
      <Navbar isNavbarOpened={isNavbarOpened} isHomePage={false} />
      {/* Navbar End  */}
      {/* Web Seo Main Component  */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <div className="px-[2rem] py-[5rem] md:px-[5.3rem] md:py-[8rem] privacy_policy">
          <h1 className="text-center leading-[2.3rem] text-[2.5rem] font-proximaNovaExtraBold mb-[3.5rem] md:text-start">
            Privacy Policy of NextBigBox.io
          </h1>
          <ul className="md:mt-2 md:w-[90%]">
            <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2">
              1. Information
            </li>
            <ul>
              <li className="mt-2">
                <b>1.</b> Whenever you get any services from our site, we
                collect your information like your Name, address, and Email
                address.{" "}
              </li>
              <li className="mt-2">
                <b>2.</b> We also collect your Internet Protocol (IP) address
                from your computer if you are proceeding with the computer. It
                is collected for your browsing information and other details.
              </li>
              <li className="mt-2">
                <b>3.</b> Email marketing- We may send the email for further
                information after getting your approval.
              </li>
            </ul>
          </ul>
          <ul>
            <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2 mt-6">
              2. Acknowledgment
            </li>
            <ul>
              <li>
                All the terms and conditions are mentioned while visiting the
                site; you must agree. Once you accept these terms and
                conditions, you can see the services and go for further process.
                Unless taking the terms and conditions, you can’t proceed
                further.{" "}
              </li>
            </ul>
          </ul>
          <ul>
            <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2 mt-6">
              3. Link to another website
            </li>
            <ul>
              <li>
                Our company may be linked to the other website for help and
                services from a third party, but accepting their terms and
                conditions will be your responsibility if you have any trouble
                getting them.
              </li>
            </ul>
          </ul>
          <ul>
            <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2 mt-6">
              4. Sharing your Information from the Third Party
            </li>
            <ul>
              <li>
                We can also share your information with a third party because of
                our association with them for different services. We can assure
                you that they will not misuse your information like your Name,
                Contact details, Address details, and other information. If you
                agree, then only you can visit the site.
              </li>
            </ul>
          </ul>
          <ul>
            <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[1.8rem] mb-2 mt-6">
              5. Change in terms and condition
            </li>
            <ul>
              <li>
                We may change the terms and conditions as per the guideline of
                the regulating bodies. So whatever guidelines are implemented,
                we must follow the same. So, there may be various changes; you
                must accept the following policies.
              </li>
            </ul>
          </ul>
          <ul>
            <li className="font-proximaNovaExtraBold text-[1.5rem] leading-[2.3rem] mb-2 mt-6">
              6. Cookies
            </li>
            <ul>
              <li>
                Like other services, we use cookies to collect information
                regarding your preference. We collect your browsing history for
                a better experience. As per your requirements, we can enhance
                our services for your better experience. You must accept the
                cookies for further assistance. Unless you accept the cookies,
                you can’t get the services.
              </li>
            </ul>
          </ul>
        </div>
      </SectionCard>
      {/* Web Seo  Main Component End */}
      {/* FooterSection Start */}
      <SectionCard
        position={"relative"}
        height={"h-auto"}
        width={"w-400"}
        backgroundColor={"bg-red"}
        color={"text-yellow"}
        className={"text-slate-800 mt-20"}
        visible={test ? "hidden" : "show"}
      >
        <NewFooterComponent />
      </SectionCard>
      {/* FooterSection End */}
    </div>
  );
}

export default PrivacyPolicy;
